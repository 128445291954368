import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { GetTextByRegion } from '../hooks/Translation';

const DeleteConfirmationDialog = ({ 
  open, 
  onClose, 
  onConfirm, 
  selectedRegion,  
  title = "Delete Item",
  content = "Are you sure you want to delete this item?",
  buttonText = "Delete",
}) => {  
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{GetTextByRegion(title,selectedRegion)}</DialogTitle>
      <DialogContent>{GetTextByRegion(content,selectedRegion)}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{GetTextByRegion("Cancel",selectedRegion)}</Button>
        <Button onClick={onConfirm} variant="contained" color="secondary">{GetTextByRegion(buttonText,selectedRegion)}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;