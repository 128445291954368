import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Optional: if you want to use the auto table feature
import scienscopelogo from '../../images/scienscopeLogo.png';
//ttf files
import '../../misc/NotoSansSC-Regular.ttf';
import '../../misc/NotoSansSC-Bold.ttf';
//font base64
import fontBase64_regular from '../../misc/NotoScansSC-Regular';
import fontBase64_bold from '../../misc/NotoScansSC-Bold';

const backendString = process.env.REACT_APP_BACKEND_STRING;

export const generatePartPDF = (report, onSuccess, onError, selectedRegion) => {

    console.log(report);
    const applicationDate = report.applicationDate;
    const notes = report.notes;
    const parts = report.parts;
    const poNumber = report.poNumber;
    const shipVia = report.shipVia;
    const vendorAddress = report.vendorAddress;
    const vendorName = report.vendorName;
    const customerName = report.customerName;
    const customerAddress = report.customerAddress;
    const customerContactPerson = report.customerContactPerson;
    const customerContactPhone = report.customerContactPhone;
    const scienscopeName = "善思科技國際有限公司\nScienscope International Ltd.";
    const scienscopeAddress = "新北市土城區忠義路70號";

    const pageMargin = 10;

    /*////////////////////////////////////////////////////////////
    //    Add text with a Underline and give it a fixed width  //
    //////////////////////////////////////////////////////////////*/
    const addTextWithUnderlines = (doc, text, x, y, maxWidth) => {
        const lines = [];
        const textHeight = doc.getTextDimensions('W').h * 1.2;
        //return if text is empty
        if (!text || text === '') {
            return y + textHeight;
        }
        //replace the newline characters
        text = text.replace(/\n/g, ' ');
        //split text if it is too long
        let line = '';
        const words = text.split(' ');
        for (const word of words) {
            const testLine = line + (line ? ' ' : '') + word;
            const testWidth = doc.getTextWidth(testLine);
            //if the current string width will be lager the max row width
            if (testWidth > maxWidth) {
                lines.push(line);
                line = word;
            } else {
                line = testLine;
            }
        }
        if (line) {
            lines.push(line);
        }

        lines.forEach(line => {
            addText(doc, line, x, y, textHeight);
            doc.line(x, y + 1, x + doc.getTextWidth(line), y + 1);
            y += textHeight;
        });
        return y;

    };


    /*/////////////////////////////////////////////////
    //    Add text and check if it fits on the page  //
    ///////////////////////////////////////////////////*/
    function addText(doc, text, x, y, Yoffset) {
        const pageHeight = doc.internal.pageSize.height;
        const pagewidth = doc.internal.pageSize.width;
        const textHeight = doc.getTextDimensions(text).h;

        text = text.replace(/\n/g, ' ');

        const lines = [];
        let line = '';
        const words = text.split(' ');
        for (const word of words) {
            const testLine = line + (line ? ' ' : '') + word;
            const testWidth = doc.getTextWidth(testLine) + pageMargin * 2;
            if (testWidth > pagewidth) {
                lines.push(line);
                line = word;
            } else {
                line = testLine;
            }
        }
        if (line) {
            lines.push(line);
        }

        for (const row of lines) {
            if (y + textHeight * 1.2 > pageHeight - pageMargin * 2) {
                doc.addPage();
                y = pageMargin;
                y += Yoffset;
            }
            doc.text(row, x, y);
            y += Yoffset;
        }
        if (lines.length === 0) {
            return y + Yoffset;
        } else {
            return y;
        }
    }

    /*////////////////////////////////////////////////////////////
  //    Add text with a box and check if it fits on the page  //
  //////////////////////////////////////////////////////////////*/
    function addTextWithRect(doc, text, x, y, maxBoxWidth) {

        let currentY = y;
        let currentX = x;
        if (currentX < pageMargin) {
            currentX = pageMargin;
        }
        let startIndex = 0;
        let contentMargin = 5;

        const pageHeight = doc.internal.pageSize.height;
        const pageWidth = doc.internal.pageSize.width;
        const textHeight = doc.getTextDimensions(text).h;
        const lineHeight = textHeight * 1.2;

        if (maxBoxWidth > pageWidth) {
            maxBoxWidth = pageWidth - 2 * pageMargin;
        }

        //split the text with a given width
        const splitText = doc.splitTextToSize(text, maxBoxWidth - 2 * contentMargin);

        while (startIndex < splitText.length) {
            // calculate current lines. = avaliable area height / line height
            const linesToShow = Math.round((pageHeight - currentY - 2 * contentMargin - pageMargin) / lineHeight);
            // calculate how much area it will take
            const lines = splitText.slice(startIndex, startIndex + linesToShow);
            const boxHeight = (lines.length) * lineHeight;

            // draw text.   
            doc.text(lines, x + contentMargin, currentY + lineHeight + contentMargin);
            doc.rect(x, currentY, maxBoxWidth, boxHeight + 2 * contentMargin);

            // update start index and current position y after draw the box
            startIndex += linesToShow;
            currentY += boxHeight + 2 * contentMargin; //top and bottom content margin

            // if there are more lines
            if (startIndex < splitText.length) {
                doc.addPage(); //new page
                currentY = pageMargin; // reset y position
            }
        }
        return currentY + lineHeight;
    }


    /*/////////////////////////////////////////////////
    //    Add image and check if it fits on the page  //
    ///////////////////////////////////////////////////*/
    function addImage(doc, imageData, x, y, imageHeight, imageWidth) {
        const pageHeight = doc.internal.pageSize.height;
        //const pagewidth = doc.internal.pageSize.width;
        const pageMargin = 10;

        if (y + imageHeight > pageHeight - pageMargin) {
            doc.addPage();
            y = pageMargin * 2;
        }
        doc.addImage(imageData, x, y, imageWidth, imageHeight);
        return y;
    }

    try {
        const doc = new jsPDF();
        doc.addFileToVFS('NotoSansSC-Regular.ttf', fontBase64_regular);
        doc.addFileToVFS('NotoSansSC-Bold.ttf', fontBase64_bold);
        doc.addFont('NotoSansSC-Regular.ttf', 'NotoScansSC', 'normal');
        doc.addFont('NotoSansSC-Bold.ttf', 'NotoScansSC', 'bold');
        doc.setFont('NotoScansSC', 'normal');

        const PageWidth = doc.internal.pageSize.getWidth();
        const PageHeight = doc.internal.pageSize.getHeight();

        const pageCenterX = (PageWidth - 3 * pageMargin) / 2;
        const Yoffset = 8;
        const lineHeight = 0.5;
        const tableWidth = PageWidth - 2 * pageMargin;
        const blackColorCode = [0, 0, 0];
        const greyColorCode = [200, 200, 200];

        let x1 = pageMargin;
        let y1 = pageMargin;
        let x2 = x1 + pageCenterX + pageMargin;
        let y2 = y1;

        /*//////////////////////////////////////////////////     scienscope logo image      ////////////////////////////////////////////////////*/
        const imgData = new Image();
        imgData.src = scienscopelogo;
        let imageWidth = 45;
        let imageHeight = 14;
        doc.addImage(imgData, 'PNG', PageWidth - pageMargin - imageWidth - 15, y1, imageWidth, imageHeight);

        /*/////////////////////////////////////////////          header for PDF report              //////////////////////////////////////////////////////*/
        doc.setFontSize(14);
        doc.setFont('NotoScansSC', 'bold');
        const Title = selectedRegion==='TAIWAN'?`零件購買表`:'Purchase Order';
        const title_x = pageCenterX - doc.getTextWidth(Title) / 2 + pageMargin;
        y1 = addTextWithUnderlines(doc, Title, title_x, y1 + 6, doc.getTextWidth(Title) + 2, pageMargin, 1);

        // Draw underline
        y2 = y1;
        /*////////////////////////////////////////////////////              main body content          ////////////////////////////////////////////////////*/
        //doc.setFontSize(10);
        const GrayColor = [105, 105, 105];
        // Information sections
        const rowsdata = [
            [
                { content: `${scienscopeName}\n${scienscopeAddress}`, colSpan: 3, rowSpan: report.dropShip ? 7 : 6, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
                { content: selectedRegion==='TAIWAN'?"日期: ":'Date', colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', lineWidth: 0 } },
                { content: `${applicationDate}`, colSpan: 2, styles: { halign: 'center', lineWidth: 0 } },
            ],
            [
                { content: selectedRegion==='TAIWAN'?`訂單號: `:'OP #', colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', lineWidth: 0 } },
                { content: `${poNumber}`, colSpan: 2, styles: { halign: 'center', lineWidth: 0, } },

            ],
            [
                { content: selectedRegion==='TAIWAN'?"發貨方式:":"Ship Via", colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', lineWidth: 0 } },
                { content: `${shipVia}`, colSpan: 2, styles: { halign: 'center', lineWidth: 0, } },
            ],
            [
                { content: report.dropShip ? selectedRegion==='TAIWAN'?"***直送客戶公司":"***Drop Ship" : " ", colSpan: 3, styles: { halign: 'center', fontStyle: 'bold', lineWidth: 0 } },
            ],
            [
                { content: report.dropShip ? selectedRegion==='TAIWAN'?`客戶聯絡名: `:"Customer Contact: " : " ", colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', lineWidth: 0 } },
                { content: report.dropShip ? `${customerContactPerson}` : "", colSpan: 2, styles: { halign: 'center', lineWidth: 0, } },
            ],
            [
                { content: report.dropShip ? selectedRegion==='TAIWAN'?`客戶連絡電話: `:"Customer Phone #: ":" ", colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', lineWidth: 0 } },
                { content: report.dropShip ?`${customerContactPhone}`:"", colSpan: 2, styles: { halign: 'center', lineWidth: 0, } },
            ],

            [//empty row
                { content: "", colSpan: 6, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
            ],
            [
                { content: selectedRegion==='TAIWAN'?"供應商: ":"Vendor: ", colSpan: 3, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
                { content: report.dropShip ? selectedRegion==='TAIWAN'?"發送地址: ":"Ship To: " : "", colSpan: 3, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },

            ],
            [
                { content: `${vendorName}\n${vendorAddress}`, colSpan: 3, styles: { halign: 'left', lineWidth: 0 } },
                { content: report.dropShip ? `${customerName}\n${customerAddress}` : "", colSpan: 3, styles: { halign: 'left', lineWidth: 0 } },
            ],
        ];

        doc.autoTable({
            body: rowsdata,
            startY: y1 + 10, // Position the table
            startX: x1,
            margin: { top: 0, left: pageMargin, bottom: 0, right: 0 }, // Set margin to zero         
            styles: {
                font: 'NotoScansSC',
                cellPadding: 1, // Cell padding
                fontSize: 9,
                halign: 'left',
                valign: 'top', 
                lineHeight: 1.2,
                textColor: blackColorCode,
                lineColor: blackColorCode, // Body border color (black)
                overflow: 'linebreak', // Handle text overflow
            },
            columnStyles: {
                0: { cellWidth: tableWidth / 6 },
                1: { cellWidth: tableWidth / 6 },
                2: { cellWidth: tableWidth / 6 },
                3: { cellWidth: tableWidth / 6 },
                4: { cellWidth: tableWidth / 6 },
                5: { cellWidth: tableWidth / 6 },
            },
            theme: 'grid',
        });

        y1 = doc.autoTable.previous.finalY + Yoffset;


        // Prepare the table data
        const tableData = parts.map((part, index) => [
            part.partNumber || '',
            part.description || "",
            part.quantity || 0,
            part.rate || 0,
            part.price || 0,
           
        ]);

        const totalAmount = tableData.reduce((sum, row) => sum + parseFloat(row[4] || 0), 0);

        // Add a total row at the end of the table
        // tableData.push(["", "", "Total", totalAmount]);

        // Generate the table with the updated data
        doc.autoTable({
            startY: y1 + 10, // Adjust based on your last content's Y position
            head: [[
                selectedRegion==='TAIWAN'?"物料號":'Part Number',
                 selectedRegion==='TAIWAN'?"描述":"Description",
                 selectedRegion==='TAIWAN'? "數量":"Quantity",
                 selectedRegion==='TAIWAN'? "單位價格":"Unit Price",
                 selectedRegion==='TAIWAN'? "金額":"Amount"]],
            body: tableData,
            theme: "grid",
            styles: { font: "NotoScansSC", fontSize: 9, cellPadding: 2, halign: 'center' },
            headStyles: { fillColor: [140, 140, 140] }, // Dark gray header       
            foot: [["", "", "", selectedRegion==='TAIWAN'?"總計":"Total", totalAmount.toFixed(2)]], // Format total amount to 2 decimal places
            footStyles: { fillColor: [240, 240, 240], textColor: [0, 0, 0], fontStyle: "bold" }, // White background, black text
            columnStyles: {
                0: { cellWidth: tableWidth * 0.30 },
                1: { cellWidth: tableWidth * 0.25 },
                2: { cellWidth: tableWidth * 0.15 },
                3: { cellWidth: tableWidth * 0.15 },
                4: { cellWidth: tableWidth * 0.15 },
            },
        });

        // Update Y position after table
        y1 = doc.lastAutoTable.finalY + 10;

        // Define the starting position for the notes box
        let notesX = pageMargin;
        let notesY = y1 + 10; // Adjust the Y position based on the table's final position

        // Sample notes text
        const notesText = report.notes || "No additional notes provided.";
        const noteTable = [
            [
                { content: selectedRegion==='TAIWAN'?"備註: ":"Note: ", styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
            ],
            [
                { content: `${notes}`, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
            ]
        ];




        doc.autoTable({
            startY: y1 + 10, // Adjust based on your last content's Y position
            body: noteTable,
            theme: "grid",
            styles: { font: "NotoScansSC", fontSize: 10, cellPadding: 2 },
            headStyles: { fillColor: [140, 140, 140] }, // Dark gray header          
            footStyles: { fillColor: [240, 240, 240], textColor: [0, 0, 0], fontStyle: "bold" }, // White background, black text
            columnStyles: {
                0: { cellWidth: tableWidth },
            },
        });

        onSuccess(doc);
        //doc.save("non-conforming");  test 
    } catch (error) {
        onError(error);
    }
};
