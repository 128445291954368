import { useState, useEffect } from "react";
import axios from 'axios';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, TextField, Autocomplete, Grid } from "@mui/material";
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const AddMachinePart = ({ open, onClose, model, setParts }) => {
    const { selectedRegion, user } = useUser();
    const [errorMessage, setErrorMesage] = useState({});
    const [opt_category, setOptionCategory] = useState(["Chasis", "Software", "PC", "Monitor", "X-Ray Tube", "Detector", "Printer", "Scanner"]);
    const [opt_chasis, setChasis] = useState([]);
    const [opt_softwares, setSoftwares] = useState([]);
    const [opt_pcs, setPCs] = useState([]);
    const [opt_monitors, setMonitors] = useState([]);
    const [opt_tubes, setTubes] = useState([]);
    const [opt_detectors, setDetectors] = useState([]);
    const [opt_printers, setPrinters] = useState([]);
    const [opt_scanners, setScanners] = useState([]);

    /* part object here contains
        part:{
            category:String,
            description:String,
            id:String,
            item:Srtring,
            model:String,
            quantity:Number,
            unitprice:Number,
        }
    */

    //all require fields 
    const requiredFields = ["category", "item", "quantity"];
    //validate the form
    const validateForm = () => {
        console.log("validate");
        const errors = {};

        if(part.category===""||
            part.item===""||
            part.quantity<=0||
            part.unitprice<0
        ){
            return false;
        }else{
            return true;
        }
    };


    //part template /////////////////////////////////
    const defaultPartTemplate = {
        id: Date.now().toString(),
        model: model === "" ? "NA" : model,
        category: "",
        item: "",
        quantity: 0,
        unitprice: 0,
        description: "",
    }
    //////////////////////////////////////////////////////
    const [part, setPart] = useState(defaultPartTemplate);

    // //test 
    // useEffect(()=>{
    //     console.log(part);
    // },[part]);

    useEffect(() => {
        const fetchData = async (type, setter) => {
            try {
                const response = await axios.get(
                    `${backendString}/dropdown?selectedRegion=${selectedRegion}&type=${type}`,
                    {
                        headers: {
                            Authorization: sessionStorage.getItem("token"),
                        },
                    }
                );
                const models = response.data
                    .filter((item) => {
                        if (item.ForMachine) {
                            const ForMachines = item.ForMachine.split(',');
                            return ForMachines.includes(part.model); // Check if machineModel exists in ForMachines
                        }
                        return true; // Include items without ForMachine
                    })
                    .map((item) => item.ModelName); // Extract ModelName           
                setter(models); // Set the filtered models
            } catch (error) {
                console.error(`Error fetching ${type}:`, error);
            }
        };
        //get category
        if (part?.category !== "") {
            //call model
            switch (part.category) {
                case "PC":
                    fetchData("pc", setPCs);
                    break;
                case "Monitor":
                    fetchData("monitor", setMonitors);
                    break;
                case "X-Ray Tube":
                    fetchData("tube", setTubes);
                    break;
                case "Tube Power Supply":
                    break;
                case "Detector":
                    fetchData("fpd", setDetectors);
                    break;
                case "Chassis":
                    fetchData("cabinetassembly", setChasis);
                    break;
                case "Software":
                    fetchData("software", setSoftwares);
                    break;
                case "Printer":
                    fetchData("barcodeprinter", setPrinters);
                    break;
                case "Scanner":
                    fetchData("barcodescanner", setScanners);
                    break;
            }
        }
    }, [part.category]);
    //add the Part to machine
    const addPart = () => {
        //console.log("add part");
        if (!validateForm()) {
            return;
        }
        //console.log("add part",part);
        setParts(prevParts => [...prevParts, part]);
        setPart(defaultPartTemplate);
        onClose();
    }
    //handle field value changes
    const handleChange = (field) => (event) => {

        setErrorMesage("");
        setPart((prevPart) => ({
            ...prevPart,
            [field]: event.target.value,
        }));
    }
    //handle category Change
    const handleCategoryChange = (value) => {
        //console.log("Selected Category:", value);
        setPart(prev => ({
            ...prev,
            category: value || '',
            item: ''
        }));
    };


    //render nessary fields for this part
    const renderPartFields = (category) => {
        let options = [];
        switch (category) {
            case "Chasis":
                options = opt_chasis;
                break;
            case "Software":
                options = opt_softwares;
                break;
            case "PC":
                options = opt_pcs;
                break;
            case "Monitor":
                options = opt_monitors;
                break;
            case "X-Ray Tube":
                options = opt_tubes;
                break;
            case "Detector":
                options = opt_detectors;
                break;
            case "Printer":
                options = opt_printers;
                break;
            case "Scanner":
                options = opt_scanners;
                break;
            default:
                options = [];
                break;
        }
        if (options.length !== 0) {
            return (
                <Autocomplete
                    freeSolo
                    options={options}
                    value={part.item}
                    onChange={(event, newValue) => setPart(prev => ({ ...prev, item: newValue }))}
                    renderInput={(params) => (
                        <TextField {...params} label={GetTextByRegion("Part Number", selectedRegion)} variant="outlined" margin="normal"/>
                    )}
                />
            );
        } else {
            return (
                <TextField
                    label={GetTextByRegion("Part Number", selectedRegion)}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={part.item}
                    onChange={(event) => setPart(prev => ({ ...prev, item: event.target.value || '' }))}                   
                />
            );
        }
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{GetTextByRegion("Add a part to machine", selectedRegion)}</DialogTitle>

            <DialogContent>

                <Grid container spacing={2} justifyContent="left" alignItems="center" mt={0}>

                    <Grid item xs={6}>
                        <TextField
                            label={GetTextByRegion("Model", selectedRegion)}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            value={part.model}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            freeSolo
                            options={opt_category}
                            getOptionLabel={(option)=>GetTextByRegion(option,selectedRegion)}
                            value={part.category}
                            onChange={(event, newValue) => handleCategoryChange(newValue)}
                            onBlur={handleChange('category')}
                            renderInput={(params) => (
                                <TextField {...params} label={GetTextByRegion("Category", selectedRegion)} variant="outlined" margin="normal"/>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {renderPartFields(part.category)}
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={GetTextByRegion("Quantity", selectedRegion)}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            type="number"
                            value={part.quantity}
                            onChange={handleChange('quantity')}
                            onKeyPress={(e) => {
                                const allowedKeys = /^[0-9]/;
                                if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                    e.preventDefault();
                                    setErrorMesage("Error: Invalid Input!");
                                }
                            }}
                           
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={GetTextByRegion("Unit Price", selectedRegion)}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            type="number"
                            value={part.unitprice}
                            onChange={handleChange('unitprice')}
                            onKeyPress={(e) => {
                                const allowedKeys = /^[0-9]+$/;
                                if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                    e.preventDefault();
                                    setErrorMesage("Error: Invalid Input!");
                                }
                            }}
                           
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={GetTextByRegion("Item Description", selectedRegion)}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            value={part.description}
                            onChange={handleChange('description')}
                        />
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button onClick={addPart} color="primary" variant="contained" >
                        {GetTextByRegion("Add", selectedRegion)}
                    </Button>
                    <Button onClick={onClose} color="primary" >
                        {GetTextByRegion("Close", selectedRegion)}
                    </Button>
                </DialogActions>

            </DialogContent>
        </Dialog>
    );

}

export default AddMachinePart;