import React, { useState, useEffect, } from 'react';
import axios from 'axios';
import { Button, TextField, Box, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, FormControlLabel, Grid, FormControl, InputLabel, Select, MenuItem, Typography, FormHelperText } from '@mui/material';
import { useUser } from '../../hooks/UserContext';
import { GetTextByRegion } from '../../hooks/Translation';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import PartNumberTextField from '../PartNumberTextField';
import { generatePartPDF } from './GeneratePartPDF'
import MachineModelDropdown from '../ChinaProduction/MachineModelDropdown';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const ProductionPartForm = ({ onAdd, onClose, isEdit, selectedItem, setData }) => {
    const today = new Date().toISOString().split('T')[0]; //get today's date
    const { selectedRegion, user: { username, email } } = useUser();

    const [validationErrors, setValidationErrors] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [dropShip, setDropShip] = useState(false);
    const [partList, setParts] = useState([{
        partNumber: '',
        price: 0,
        quantity: 0,
        rate: 0,
        description: '',
    }]);

    const productionPartFormTemplate = {
        poNumber: '',
        applicationDate: today,
        shipVia: '',
        notes: '',
        totalAmount: 0,
        numParts: 1, // New field to control the number of parts
        vendorName: selectedRegion === 'TAIWAN' ? '善思光电科技有限公司' : '',
        vendorAddress: selectedRegion === 'TAIWAN' ? '江蘇省崑山市經濟技術開發區琵琶路28號' : '',
        customerName: '', //company name
        customerAddress: '',//company address  
        customerContactPerson: '',//customer contact name     
        customerContactPhone: '',//customer contact phone
    }
    const [formData, setFormData] = useState(productionPartFormTemplate);


    //all require fields 
    const requiredFields = [];

    //initialize
    useEffect(() => {
        if (isEdit && selectedItem) {           
            console.log(selectedItem);

            setDropShip(selectedItem.dropShip);
            setParts(selectedItem.parts);   
            const newSelectedItem = {...selectedItem,numParts:selectedItem.parts.length}
            setFormData(newSelectedItem);

        } else {
            setFormData(productionPartFormTemplate);
        }
    }, [selectedItem]);

    //when the parts data is changed, recalculate the total
    useEffect(() => {
        let total = partList.reduce((sum, part) => sum + part.price, 0);
        setFormData(prevFormData => ({
            ...prevFormData,
            totalAmount: total
        }));
    }, [partList]);

    //submit
    const addProductionPart = async () => {
        setFormSubmitted(true);
        let updatedFormData = { ...formData, parts: partList ,dropShip:dropShip };

        if (isEdit) {
            console.log("Edit mode");
            try {
                let connectionString = backendString + `/productionParts/${selectedItem._id}`;
                const response = await axios.put(`${connectionString}`, updatedFormData, {
                    headers: {
                        'Authorization': sessionStorage.getItem('token'),
                    },
                });
                // Update the specific entry in the existing data
                setData(prevData => prevData.map(item =>
                    item._id === response.data._id ? response.data : item
                ));

            } catch (error) {
                console.error('Error adding production part:', error);
                setValidationErrors({ general: error.response?.data?.error });

            }
        } else {
            try {
                //get newPO number
                let response = await axios.post(`${backendString}/newPO/`);
                let newpo = response.data.poNumber;
                updatedFormData = { ...updatedFormData, poNumber: newpo, };
                setFormData(updatedFormData);

            } catch (error) {
                console.error("Error Creating ponumber...", error);
                return;
            }

            try {
                let connectionString = backendString + '/productionParts';
                const response = await axios.post(`${connectionString}`, updatedFormData, {
                    headers: {
                        'Authorization': sessionStorage.getItem('token'),
                    },
                });
                onAdd(response.data);
                // Generate the PDF and send the email
                handleGeneratePDF(response.data, async (emailAttachment) => {
                    try {
                        await axios.post(`${backendString}/productionParts/send-email/attachment`, emailAttachment, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                        });
                    } catch (error) {
                        console.error('Error sending email:', error);
                    }
                });

            } catch (error) {
                console.error('Error adding production part:', error);
                setValidationErrors({ general: error.response?.data?.error });
            }
        }
        setFormData(productionPartFormTemplate);
        onClose();
        setFormSubmitted(false);

    };
    //handle text field event
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    //handle drop ship check box event
    const handleDropShipChange = (event) => {
        setDropShip(event.target.checked);
    }

    //handle part quantity for this order
    const handleNumPartsChange = (event) => {
        const numParts = parseInt(event.target.value, 10) || 1; // Ensure it's a valid number
        const newParts = [];
        for (let i = 0; i < numParts; i++) {
            newParts.push({
                partNumber: '',
                price: 0,
                quantity: 0,
                rate: 0,
                description: '',
            });
        }
        setParts(newParts);
        //update the quantity of the part
        setFormData(prev => ({ ...prev, numParts: newParts.length }));

    };


    const validateForm = () => {
        const errors = {};
        if (!formData.poNumber) errors.poNumber = 'PO Number is required';
        if (!formData.applicationDate) errors.applicationDate = 'Application Date is required';
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    //create PDF file
    const handleGeneratePDF = (report, callback) => {
        generatePartPDF(report, async (doc) => {

            const response = await axios.get(backendString + '/users/email/CFO_EMAIL');
            const pdfBlob = doc.output('blob'); // Generate PDF as Blob

            const formData = new FormData();
            formData.append('file', new Blob([pdfBlob], { type: 'application/pdf' }), 'Purchase Order.pdf');
            formData.append('email', response.data.email); // Adjust based on your API requirements

            callback(formData);
        }, (error) => {
            console.error('Failed to generate PDF:', error);
        }, selectedRegion);
    };

    //text field
    const TextFieldTemplate = (fieldName, headerName, width = 6, row = 1, type = "text", variant = "outlined", multiline = false) => {
        return (
            <Grid item xs={width}>
                <TextField
                    fullWidth
                    required={requiredFields.includes(fieldName)}
                    error={!!validationErrors[fieldName]}
                    helperText={validationErrors[fieldName] || ""}
                    multiline={multiline}
                    rows={row > 1 ? row : null}
                    label={GetTextByRegion(headerName, selectedRegion)}
                    variant={variant}
                    value={formData[fieldName]}
                    name={fieldName}
                    onChange={handleChange}
                    type={type}
                    InputLabelProps={
                        type === "date" ? { shrink: true } : undefined
                    }
                    disabled={fieldName === "owner" || fieldName === "region" || fieldName === "owneremail"}
                />
            </Grid>
        );
    }
    //handle part fields change event
    const handlePartChange = (index, field, value) => {

        const updatedParts = [...partList];
        updatedParts[index] = {
            ...updatedParts[index],
            [field]: value,
        };

        if (field === 'quantity' || field === 'rate') {
            updatedParts[index].price = Number(updatedParts[index].rate) * Number(updatedParts[index].quantity);
        }
        setParts(updatedParts);
    };

    return (
        <Dialog open={true} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>{GetTextByRegion("Production Part Order", selectedRegion)}{isEdit ? `: ${formData.poNumber}` : ""}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} justifyContent="left" alignItems="center" mt={0}>
                    <Grid item xs={6}>
                        <TextField
                            label={GetTextByRegion("Application Date", selectedRegion)}
                            name="applicationDate"
                            type="date"
                            value={formData.applicationDate}
                            onChange={handleChange}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            error={!!validationErrors.applicationDate}
                            helperText={validationErrors.applicationDate}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-amount">
                                {GetTextByRegion("Total Price", selectedRegion)}
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-amount"
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                onChange={handleChange}
                                disabled={true}
                                value={formData.totalAmount}
                                label="totalAmount"
                                name='totalAmount'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={GetTextByRegion("Vendor Name", selectedRegion)}
                            name="vendorName"
                            value={formData.vendorName}
                            onChange={handleChange}
                            variant="outlined"
                            error={!!validationErrors.vendorName}
                            helperText={validationErrors.vendorName}
                            fullWidth
                            multiline
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={GetTextByRegion("Vendor Address", selectedRegion)}
                            name="vendorAddress"
                            value={formData.vendorAddress}
                            onChange={handleChange}
                            variant="outlined"
                            error={!!validationErrors.vendorAddress}
                            helperText={validationErrors.vendorAddress}
                            fullWidth
                            multiline
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MachineModelDropdown
                            selectedRegion={selectedRegion}
                            GetTextByRegion={GetTextByRegion}
                            handleChange={handleChange}
                            validationErrors={validationErrors}
                            machine={formData}
                            type="shipvia" // Set to "option"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="dropShip"
                                    color="primary"
                                    checked={dropShip}
                                    onChange={(e) => handleDropShipChange(e)}
                                />
                            }
                            label={GetTextByRegion("Drop Ship", selectedRegion)}
                        />
                    </Grid>
                    {
                        dropShip === false ? (
                            <></>
                        ) : (
                            <>
                                {
                                    TextFieldTemplate('customerName', 'Company', 6, 1, "text", "outlined", true)
                                }
                                {
                                    TextFieldTemplate("customerAddress", "Customer Address", 6, 1, "text", "outlined", true)
                                }
                                {
                                    TextFieldTemplate("customerContactPerson", "Contact Person", 6, 1)
                                }
                                {
                                    TextFieldTemplate("customerContactPhone", "Contact Phone #", 6, 1)
                                }
                            </>
                        )
                    }
                    <Grid item xs={12} >
                        <TextField
                            label={GetTextByRegion("Notes", selectedRegion)}
                            name="notes"
                            value={formData.notes}
                            onChange={handleChange}
                            variant="outlined"
                            rows={4}
                            multiline
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>

                        <FormControl fullWidth error={!!validationErrors.numParts}>
                            <InputLabel id="numParts-label">
                                {GetTextByRegion("Number of Parts", selectedRegion)}
                            </InputLabel>
                            <Select
                                labelId="numParts-label"
                                name="numParts"
                                value={formData.numParts}
                                onChange={handleNumPartsChange}
                                label={GetTextByRegion("Number of Parts", selectedRegion)} // Ensure label is provided
                            >
                                {[...Array(20)].map((_, index) => (
                                    <MenuItem key={index + 1} value={index + 1}>
                                        {index + 1}
                                    </MenuItem>
                                ))}
                            </Select>
                            {validationErrors.numParts && <FormHelperText>{validationErrors.numParts}</FormHelperText>}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2} justifyContent="left" alignItems="center" mt={0}>
                            <Grid item xs={12}>
                                <Typography variant="h6">{GetTextByRegion("Part Details", selectedRegion)}</Typography>
                            </Grid>

                            {partList.length > 0 && (
                                partList.map((row, index) => (
                                    <React.Fragment key={index}>
                                        <Grid item xs={9} >
                                            <PartNumberTextField
                                                name={`partnumberTextfield-${index}`}
                                                value={partList[index].partNumber}
                                                variant="outlined"
                                                fullWidth
                                                selectedRegion={selectedRegion}
                                                index={index}
                                                showPrice={true}
                                                showDescription={true}
                                                descriptionValue={partList[index].description}
                                                onChange={(newPartNumber, newPrice, newDescription) => {
                                                    console.log(newPartNumber + " " + newPrice + " " + newDescription);
                                                    const newPart = {
                                                        partNumber: newPartNumber,
                                                        price: 0,
                                                        quantity: 0,
                                                        rate: Number(newPrice),
                                                        description: newDescription,
                                                    }
                                                    const updatedParts = [...partList];
                                                    updatedParts[index] = newPart;
                                                    setParts(updatedParts);
                                                }}
                                                onDescriptionChange={(e) => handlePartChange(index, 'description', e)}
                                                onPriceChange={(e) => handlePartChange(index, 'rate', e)} //unit price
                                            />
                                        </Grid >
                                        <Grid item xs={1.5} >
                                            <TextField
                                                label={GetTextByRegion(`Quantity`, selectedRegion) + ` ${index + 1}`}
                                                name={`quantity-${index}`}
                                                type="number"
                                                value={partList[index].quantity}
                                                onChange={(e) => handlePartChange(index, 'quantity', e.target.value)}
                                                variant="outlined"
                                                error={!!validationErrors.quantity}
                                                helperText={validationErrors.quantity}
                                                fullWidth
                                            />
                                        </Grid >
                                        <Grid item xs={1.5}>
                                            <TextField
                                                label={GetTextByRegion(`Amount`, selectedRegion) + ` ${index + 1}`}
                                                name={`rate-${index}`}
                                                type="number"
                                                value={partList[index].price}
                                                variant="outlined"
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                    </React.Fragment>
                                ))
                            )}
                        </Grid>
                    </Grid>
                </Grid>

            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    {GetTextByRegion("Cancel", selectedRegion)}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={addProductionPart}
                    disabled={!formData.applicationDate || formData.totalAmount === 0}
                >
                    {isEdit ? GetTextByRegion("Save", selectedRegion) : GetTextByRegion("Add Production Part", selectedRegion)}
                </Button>
            </DialogActions>
        </Dialog>

    );
};

export default ProductionPartForm;
