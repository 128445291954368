import { Button, TextField, Box, Tooltip, Dialog, DialogContent, Checkbox, FormControlLabel, DialogTitle, DialogActions, IconButton, TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell, Grid } from "@mui/material";
import AddMachinePart from "./AddMachinePartView";
import EditMachinePart from "./EditMachinePartView";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';
import { useState, useRef, useEffect } from "react";
import ModelDropdown from '../ModelDropdown';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const EditMachineForm = ({ open, onClose, machine, onEdit }) => {
    const { selectedRegion, user } = useUser();
    const [parts, setParts] = useState(machine.detail);
    const [selectedPart, setSelectedPart] = useState(null);
    const [addMachinePartView, setAddMachinePartView] = useState(false);
    const [editMachinePartView, setEditMachinePartView] = useState(false);
    const [currentMachine, setCurrentMachine] = useState(machine);
    const tableContainerRef = useRef(null);

    ///////////////////////////////////////////////////
    //open the add machine part window
    const openAddMachinePart = () => {
        setAddMachinePartView(true);
    }
    //close the add machine part window
    const closeAddMachinePart = () => {
        setAddMachinePartView(false);
    }
    /////////////////////////////////////////////////////
    //delete the part
    const handleDeleteMachinePart = (part) => {
        setParts((prevParts) => prevParts.filter(item => item.id !== part.id));
    }
    ///////////////////////////////////////////////////
    const handleCurrentMachineChange = (event) => {
        const { name, value, checked } = event.target;
        if (name === "machineCustomPrice") {
            setCurrentMachine((prevMachine) => ({
                ...prevMachine,
                [name]: checked
            }));
        } else {
            setCurrentMachine((prevMachine) => ({
                ...prevMachine,
                [name]: value
            }));
        }
    }
    ///////////////////////////////////////////////////
    const editMachineToOrder = () => {
        onEdit(currentMachine);
    }
    ///////////////////////////////////////////////////
    //open edit machine part window
    const openEditMachinePart = () => {
        setEditMachinePartView(true);
    }
    //close edit machine part window
    const closeEditMachinePart = () => {
        setEditMachinePartView(false);
    }

    const handleEditPart = (part) => {
        setSelectedPart(part);
        openEditMachinePart();
    }

    const saveEditPart = (newPart) => {
        const newParts = parts.map(part =>
            part.id === newPart.id ? newPart : part
        )
        console.log("new parts", newParts);
        setParts(newParts);
        closeEditMachinePart();
    }
    ///////////////////////////////////////////////////////
    //calculate the total cost of the parts
    useEffect(() => {
        //console.log(parts);
        const calculatePartsTotal = () => {
            let total = 0;
            parts.forEach(part => {
                total += (part.quantity * part.unitprice);
            });
            setCurrentMachine({ ...currentMachine, machinePrice: total, detail: parts });
        }
        calculatePartsTotal();
    }, [parts]);

    //update the machine total 
    useEffect(() => {
        console.log(currentMachine);
    }, [currentMachine]);

    return (
        <Dialog open={true} maxWidth="md" fullWidth>
            <DialogTitle> {GetTextByRegion("Edit machine information", selectedRegion)} </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} justifyContent="left" alignItems="center" mt={0}>
                    <Grid item xs={6}>
                        <ModelDropdown
                            value={currentMachine.model}
                            name="model"
                            required
                            onChange={handleCurrentMachineChange}
                            selectedRegion={selectedRegion}
                            type={'both'}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="contained" color="primary" onClick={openAddMachinePart}>
                            {GetTextByRegion("Add Part to Machine", selectedRegion)}
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="machineCustomPrice"
                                    color="primary"
                                    checked={currentMachine.machineCustomPrice}
                                    onChange={handleCurrentMachineChange}
                                />
                            }
                            label={GetTextByRegion("Hide $0", selectedRegion)}
                        />
                    </Grid>
                    <Grid item xs={12}>

                        <TableContainer
                            component={Paper}
                            ref={tableContainerRef}
                            sx={{
                                mt: 2,
                                maxHeight: 300,
                                overflow: 'auto'
                            }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{GetTextByRegion("Category", selectedRegion)}</TableCell>
                                        <TableCell>{GetTextByRegion("Part Number", selectedRegion)}</TableCell>
                                        <TableCell>{GetTextByRegion("Quantity", selectedRegion)}</TableCell>
                                        <TableCell>{GetTextByRegion("Unit Price", selectedRegion)}</TableCell>
                                        <TableCell>{GetTextByRegion("Description", selectedRegion)}</TableCell>
                                        <TableCell>{GetTextByRegion("Amount", selectedRegion)}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {parts.map((part, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{part.category}</TableCell>
                                            <TableCell>{part.item}</TableCell>
                                            <TableCell>{part.quantity}</TableCell>
                                            <TableCell>{parseInt(part.unitprice) === 0 && currentMachine.machineCustomPrice === true ?"-" : parseFloat(part.unitprice).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</TableCell>
                                            <TableCell>{part.description}</TableCell>
                                            <TableCell>{parseInt(part.unitprice) === 0 && currentMachine.machineCustomPrice === true ?"-":parseFloat(part.unitprice * part.quantity).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</TableCell>
                                            <TableCell>
                                                <Box>
                                                    <Tooltip title={GetTextByRegion("Edit", selectedRegion)} arrow>
                                                        <IconButton aria-label="Edit" color="primary" onClick={() => handleEditPart(part)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={GetTextByRegion("Delete", selectedRegion)} arrow>
                                                        <Button>
                                                            <IconButton aria-label="delete" color="secondary" onClick={() => handleDeleteMachinePart(part)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Button>
                                                    </Tooltip>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <Box style={{ marginTop: 16, textAlign: 'right' }}>
                            <strong>{GetTextByRegion("Machine Grand Total", selectedRegion)}:</strong>
                            <strong>${(currentMachine.machinePrice).toFixed(2)}</strong>
                        </Box>
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline={true}
                            rows={3}
                            name={'machineNote'}
                            value={currentMachine.machineNote}
                            onChange={handleCurrentMachineChange}
                            label={GetTextByRegion('Notes', selectedRegion)}
                            InputLabelProps={
                                { shrink: true }
                            }
                        />
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button variant="outlined" color="secondary" onClick={() => { onClose(); }}>
                        {GetTextByRegion("Cancel", selectedRegion)}
                    </Button>
                    <Button variant="contained" color="primary" onClick={editMachineToOrder}>
                        {GetTextByRegion("Confirm", selectedRegion)}
                    </Button>
                </DialogActions>

            </DialogContent>
            {addMachinePartView && (
                <AddMachinePart open={addMachinePartView} onClose={closeAddMachinePart} model={currentMachine.model} setParts={setParts} />
            )}
            {editMachinePartView && (<EditMachinePart open={editMachinePartView} onClose={closeEditMachinePart} part={selectedPart} onEdit={saveEditPart} />)}
        </Dialog>

    );
}
export default EditMachineForm;