import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete, TextField, InputAdornment, IconButton, Dialog, DialogActions, Button, Grid, Box } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import axios from 'axios';
import { GetTextByRegion } from '../hooks/Translation';
import { Html5QrcodeScanner } from 'html5-qrcode';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const PartNumberTextField = ({
  value,
  priceValue = '0.0',
  descriptionValue = '',
  onChange,
  required,
  formSubmitted,
  index,
  disabled,
  selectedRegion,
  inhouse = false,
  showPrice = false, // New optional parameter
  showDescription = false, // New optional parameter
  onPriceChange, // Callback for price changes
  onDescriptionChange, // Callback for price changes
}) => {
  const [partNumbers, setPartNumbers] = useState([]);
  const [priceMapping, setPriceMapping] = useState({});
  const [descriptionMapping, setDescriptionMapping] = useState({});
  const [inputValue, setInputValue] = useState(value || '');
  const [price, setPrice] = useState(priceValue);
  const [description, setDescription] = useState(descriptionValue);
  const [error, setError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const scannerRef = useRef(null);

  useEffect(() => {
    const fetchPartNumbers = async () => {
      if (!disabled) {
        try {
          const response = await axios.get(
            `${backendString}/items?selectedRegion=${selectedRegion === 'MEXICO' ? 'USA' : selectedRegion
            }`,
            {
              headers: {
                Authorization: sessionStorage.getItem('token'),
              },
            }
          );
          // Create price mapping
          const priceMap = {};
          const descMap = {};
          const partNumbers = response.data
            .map((item) => {
              if (item.partnumber) {
                priceMap[item.partnumber] = item.price;
                descMap[item.partnumber] = item.name;
                return item.partnumber;
              }
              return null;
            })
            .filter(Boolean);

          setPartNumbers(partNumbers);
          setPriceMapping(priceMap);
          setDescriptionMapping(descMap);
        } catch (error) {
          console.error('Error fetching part numbers:', error);
        }
      }
    };

    fetchPartNumbers();
  }, [selectedRegion, disabled]);

  // useEffect(() => {
  //   setInputValue(value || '');
  //   if (value && priceMapping[value]) {
  //     setPrice(priceMapping[value]); // Update price when part number changes
  //   }
  // }, [value, priceMapping]);

  useEffect(() => {
    setInputValue(value || ''); // Initialize inputValue
  }, [value]);

  useEffect(() => {
    setPrice(priceValue || '0.0');
  }, [priceValue]);

  useEffect(() => {
    setDescription(descriptionValue || '');
  }, [descriptionValue]);

  useEffect(() => {
    if (formSubmitted && required && !inputValue) {
      setError(true);
    } else {
      setError(false);
    }
    if (formSubmitted && onPriceChange && !price) {
      setPriceError(true);
    } else {
      setPriceError(false);
    }
  }, [formSubmitted, inputValue, required, onPriceChange]);

  const handleInputChange = (event, newInputValue) => {
    const updatedPrice = showPrice ? (priceMapping[newInputValue] || '') : '0.0'; //map price if showPrice, else set to 0.0
    setPrice(updatedPrice); // Update the price state
    setDescription(descriptionMapping[newInputValue] || ''); // Update the description state

    if (onChange) {
      onChange(newInputValue, updatedPrice,description); // Pass the part number and price
    }
  };

  const handlePriceChange = (event) => {
    const newPrice = event.target.value;
    setPrice(newPrice);

    if (onPriceChange) {
      onPriceChange(newPrice);
    }
  };

  const handleDescriptionChange = (event) => {
    const newDescription = event.target.value;
    setDescription(newDescription);

    if (onDescriptionChange) {
      onDescriptionChange(newDescription);
    }
  };

  const handleCameraClick = () => {
    setIsScannerOpen(true);
  };

  useEffect(() => {
    if (isScannerOpen) {
      setTimeout(() => {
        const scanner = new Html5QrcodeScanner('qr-reader', { fps: 10, qrbox: 250 });
        scanner.render((decodedText) => {
          setInputValue(decodedText);
          setIsScannerOpen(false);
        });
        scannerRef.current = scanner;
      }, 100);
    }

    return () => {
      if (scannerRef.current) {
        scannerRef.current.clear().catch((error) => console.error('Clear scanner error:', error));
      }
    };
  }, [isScannerOpen]);

  return (
    <>
      <Grid container spacing={2} mb={1}>

        {/* Part Number Field */}
        <Grid item xs={!showPrice ? 12 : (showPrice && showDescription) ? 6 :8}>
          <Box>
            <Autocomplete
              freeSolo
              options={partNumbers}
              inputValue={inputValue || ''}
              onInputChange={handleInputChange}
              disabled={disabled}
              onChange={(event, newValue) => {
                const finalValue = newValue;
                // const finalValue = newValue || inputValue;
                setPrice(priceMapping[finalValue] || '');
                if (!finalValue) {
                  setInputValue('');
                }
                onChange(finalValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    index < 0
                      ? GetTextByRegion('Part Number', selectedRegion)
                      : !inhouse
                        ? `${GetTextByRegion('Part Number', selectedRegion)} ${index + 1}`
                        : `${GetTextByRegion('Item', selectedRegion)} ${index + 1}`
                  }
                  variant="outlined"
                  fullWidth
                  required={required}
                  error={error}
                  helperText={error ? GetTextByRegion('This field is required', selectedRegion) : ''}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                        {!disabled && (
                          <InputAdornment position="end">
                            <IconButton color="primary" onClick={handleCameraClick}>
                              <QrCodeScannerIcon />
                            </IconButton>
                          </InputAdornment>
                        )}
                      </>
                    ),
                  }}
                />
              )}
            />

          </Box>
        </Grid>

        {/* Description Field */}
        {(showDescription) && (
          <Grid item xs={4}>
            <Box>
              <TextField
                label={
                  index < 0
                    ? GetTextByRegion('Description', selectedRegion)
                    : `${GetTextByRegion('Description', selectedRegion)} ${index + 1}`
                }
                value={description}
                onChange={handleDescriptionChange}
                disabled={disabled}
                fullWidth
                multiline
                variant="outlined"
              />
            </Box>
          </Grid>
        )}

        {/* Price Field */}
        {(showPrice) && (
          <Grid item xs={!showDescription ? 4 : 2}>
            <Box>
              <TextField
                label={
                  index < 0
                    ? GetTextByRegion('Price', selectedRegion)
                    : `${GetTextByRegion('Price', selectedRegion)} ${index + 1}`
                }
                value={price}
                onChange={handlePriceChange}
                disabled={disabled}
                error={priceError}
                helperText={priceError ? GetTextByRegion('This field is required', selectedRegion) : ''}
                fullWidth
                type="number"
                variant="outlined"
              />
            </Box>
          </Grid>
        )}
      </Grid>

      <Dialog open={isScannerOpen} onClose={() => setIsScannerOpen(false)} maxWidth="md" fullWidth>
        <div id="qr-reader" style={{ width: '100%', height: '400px' }}></div>
        <DialogActions>
          <Button onClick={() => setIsScannerOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PartNumberTextField;