import React, { useState } from 'react';
import axios from 'axios';
import { Box, IconButton, Typography, Tooltip, Button } from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { GetTextByRegion } from '../../hooks/Translation';
import roles from '../../hooks/Roles';
import CustomDataGrid from '../CustomDataGrid';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import ErrorDialog from '../ErrorDialog';
import useWindowSize from '../../hooks/useWindowSize';
import ProductionPartForm from '../ProductionParts/ProductionPartForm';
import { generatePartPDF } from './GeneratePartPDF'


const ProductionPartTable = ({ data, setData, userRights, selectedRegion }) => {
    const backendString = process.env.REACT_APP_BACKEND_STRING || '';
    const [selectedItem, setSelectedItem] = useState();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openErrorDialog, setErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { windowSize } = useWindowSize();

    const commonStyles = { fontSize: '14px', fontWeight: 'bold' };

    //table columns
    const columns = [
        {
            field: 'poNumber',
            headerName: GetTextByRegion("PO Number", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>{GetTextByRegion("PO Number", selectedRegion)}</Typography>,
        },
        {
            field: 'applicationDate',
            headerName: GetTextByRegion("PO Date", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>{GetTextByRegion("PO Date", selectedRegion)}</Typography>,
        },   
        {
            field: 'totalAmount',
            headerName: GetTextByRegion("Total Price", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>{GetTextByRegion("Total Price", selectedRegion)}</Typography>,           
        },
        {
            field: 'customerName',
            headerName: GetTextByRegion("Company", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>{GetTextByRegion("Company", selectedRegion)}</Typography>,         
        },
        {
            field: 'customerAddress',
            headerName: GetTextByRegion("Customer Address", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>{GetTextByRegion("Customer Address", selectedRegion)}</Typography>,
        },
        {
            field: 'vendorName',
            headerName: GetTextByRegion("Vendor Name", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>{GetTextByRegion("Vendor Name", selectedRegion)}</Typography>,
        },
        {
            field: 'vendorAddress',
            headerName: GetTextByRegion("Vendor Address", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>{GetTextByRegion("Vendor Address", selectedRegion)}</Typography>,
        },
        {
            field: 'notes',
            headerName: GetTextByRegion("Notes", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>{GetTextByRegion("Notes", selectedRegion)}</Typography>,
        },
        {
            field: 'actions',
            headerName: GetTextByRegion("Actions", selectedRegion),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderHeader: () => <Typography sx={commonStyles}>{GetTextByRegion("Actions", selectedRegion)}</Typography>,
            renderCell: (params) => (
                userRights >= roles['SALES'] && (
                    <Box>
                        <Tooltip title={GetTextByRegion("Edit", selectedRegion)} arrow>
                            <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(params)}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={GetTextByRegion("download PDF", selectedRegion)} arrow>
                            <IconButton
                                color="success"
                                onClick={() => handleGeneratePDF(params)}
                                aria-label="generate-pdf"
                            >
                                <FileDownloadIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={GetTextByRegion("Delete", selectedRegion)} arrow>
                            <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(params)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>

                    </Box>
                )
            ),
        },
    ];

    //delete the order record
    const handleDelete = (params) => {
        setSelectedItem(params.row);
        setOpenDeleteDialog(true);
    };

    //close edit window
    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setSelectedItem(null);
    };

    //close delete confirm dialog
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setSelectedItem(null);
    };

    //delete event
    const handleDeleteConfirm = async () => {
        try {
            await axios.delete(`${backendString}/productionParts/${selectedItem._id}`, {
                headers: {
                    'Authorization': sessionStorage.getItem('token'),
                },
            });
            setData(prevData => prevData.filter(item => item._id !== selectedItem._id));
            setOpenDeleteDialog(false);
        } catch (error) {
            setErrorMessage(error.response?.data?.error || "Error deleting record");
            handleOpenErrorDialog();
        }
    };
    //edit event
    const handleEditClick = (params) => {
        setSelectedItem(params.row);
        setOpenEditDialog(true);
    };
    //confirm event
    const handleConfirmClick = (params) => {
        setSelectedItem(params.row);
        setOpenConfirmDialog(true);
    };
    //open error dialog
    const handleOpenErrorDialog = () => setErrorDialog(true);
    const handleCloseErrorDialog = () => {
        setErrorDialog(false);
        setErrorMessage('');
    };

    //create PDF event
    const handleGeneratePDF = (report) => {
        generatePartPDF(report.row, (doc) => {
            doc.save('productionPart.pdf');
        }, (error) => {
            console.error('Failed to generate PDF:', error);
        }, selectedRegion);
    };


    return (
        <div style={{ height: windowSize.height * 0.77 }}>

            <CustomDataGrid rows={data} columns={columns} />

            {openEditDialog && (
                <ProductionPartForm open={openEditDialog} onClose={handleCloseEditDialog} isEdit={true} selectedItem={selectedItem} setData={setData} />
            )}

            {openDeleteDialog && (
                <DeleteConfirmationDialog
                    open={openDeleteDialog}
                    onClose={handleCloseDeleteDialog}
                    onConfirm={handleDeleteConfirm}
                    selectedRegion={selectedRegion}
                />
            )}

            {openErrorDialog && (
                <ErrorDialog open={openErrorDialog} onClose={handleCloseErrorDialog} message={errorMessage} />
            )}
        </div>
    );
};

export default ProductionPartTable;
