import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Optional: if you want to use the auto table feature
import scienscopelogo from '../../images/scienscopeLogo.png';
//ttf files
import '../../misc/NotoSansSC-Regular.ttf';
import '../../misc/NotoSansSC-Bold.ttf';
//font base64
import fontBase64_regular from '../../misc/NotoScansSC-Regular';
import fontBase64_bold from '../../misc/NotoScansSC-Bold';
import { yellow } from '@mui/material/colors';

const backendString = process.env.REACT_APP_BACKEND_STRING;

export const generateMachineOrderPDF = (data) => {

  //fix information
  const scienscopeName = "善思科技國際有限公司\nScienscope International Ltd.";
  const scienscopeAddress = "新北市土城區忠義路70號";
  const vendorName = data.vendorname;
  const vendorAddress = data.vendoraddress;
  const customerName = data.customername;
  const customerAddress = data.customeraddress;
  const customerContactPerson = data.customercontactperson;
  const customerContactPhone = data.customercontactphone;
  const machineinfo = data.machines;
  const price = data.orderprice;
  const applican = data.owner;
  const applicanEmail = data.owneremail;
  const PODate = data.podate;
  const PONumber = data.ponumber;
  const region = data.region;
  const specialRequirement = data.specialrequirement;
  const shippingTerms = data.shipVia;


  const pageMargin = 10;

  /*////////////////////////////////////////////////////////////
  //    Add text with a Underline and give it a fixed width  //
  //////////////////////////////////////////////////////////////*/
  const addTextWithUnderlines = (doc, text, x, y, maxWidth) => {
    const lines = [];
    const textHeight = doc.getTextDimensions('W').h * 1.2;
    //return if text is empty
    if (!text || text === '') {
      return y + textHeight;
    }
    //replace the newline characters
    text = text.replace(/\n/g, ' ');
    //split text if it is too long
    let line = '';
    const words = text.split(' ');
    for (const word of words) {
      const testLine = line + (line ? ' ' : '') + word;
      const testWidth = doc.getTextWidth(testLine);
      //if the current string width will be lager the max row width
      if (testWidth > maxWidth) {
        lines.push(line);
        line = word;
      } else {
        line = testLine;
      }
    }
    if (line) {
      lines.push(line);
    }

    lines.forEach(line => {
      addText(doc, line, x, y, textHeight);
      doc.line(x, y + 1, x + doc.getTextWidth(line), y + 1);
      y += textHeight;
    });
    return y;

  };


  /*/////////////////////////////////////////////////
  //    Add text and check if it fits on the page  //
  ///////////////////////////////////////////////////*/
  function addText(doc, text, x, y, Yoffset) {
    const pageHeight = doc.internal.pageSize.height;
    const pagewidth = doc.internal.pageSize.width;
    const textHeight = doc.getTextDimensions(text).h;

    text = text.replace(/\n/g, ' ');

    const lines = [];
    let line = '';
    const words = text.split(' ');
    for (const word of words) {
      const testLine = line + (line ? ' ' : '') + word;
      const testWidth = doc.getTextWidth(testLine) + pageMargin * 2;
      if (testWidth > pagewidth) {
        lines.push(line);
        line = word;
      } else {
        line = testLine;
      }
    }
    if (line) {
      lines.push(line);
    }

    for (const row of lines) {
      if (y + textHeight * 1.2 > pageHeight - pageMargin * 2) {
        doc.addPage();
        y = pageMargin;
        y += Yoffset;
      }
      doc.text(row, x, y);
      y += Yoffset;
    }
    if (lines.length === 0) {
      return y + Yoffset;
    } else {
      return y;
    }
  }

  /*////////////////////////////////////////////////////////////
//    Add text with a box and check if it fits on the page  //
//////////////////////////////////////////////////////////////*/
  function addTextWithRect(doc, text, x, y, maxBoxWidth) {

    let currentY = y;
    let currentX = x;
    if (currentX < pageMargin) {
      currentX = pageMargin;
    }
    let startIndex = 0;
    let contentMargin = 5;

    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    const textHeight = doc.getTextDimensions(text).h;
    const lineHeight = textHeight * 1.2;

    if (maxBoxWidth > pageWidth) {
      maxBoxWidth = pageWidth - 2 * pageMargin;
    }

    //split the text with a given width
    const splitText = doc.splitTextToSize(text, maxBoxWidth - 2 * contentMargin);

    while (startIndex < splitText.length) {
      // calculate current lines. = avaliable area height / line height
      const linesToShow = Math.round((pageHeight - currentY - 2 * contentMargin - pageMargin) / lineHeight);
      // calculate how much area it will take
      const lines = splitText.slice(startIndex, startIndex + linesToShow);
      const boxHeight = (lines.length) * lineHeight;

      // draw text.   
      doc.text(lines, x + contentMargin, currentY + lineHeight + contentMargin);
      doc.rect(x, currentY, maxBoxWidth, boxHeight + 2 * contentMargin);

      // update start index and current position y after draw the box
      startIndex += linesToShow;
      currentY += boxHeight + 2 * contentMargin; //top and bottom content margin

      // if there are more lines
      if (startIndex < splitText.length) {
        doc.addPage(); //new page
        currentY = pageMargin; // reset y position
      }
    }
    return currentY + lineHeight;
  }


  /*/////////////////////////////////////////////////
  //    Add image and check if it fits on the page  //
  ///////////////////////////////////////////////////*/
  function addImage(doc, imageData, x, y, imageHeight, imageWidth) {
    const pageHeight = doc.internal.pageSize.height;
    //const pagewidth = doc.internal.pageSize.width;
    const pageMargin = 10;

    if (y + imageHeight > pageHeight - pageMargin) {
      doc.addPage();
      y = pageMargin * 2;
    }
    doc.addImage(imageData, x, y, imageWidth, imageHeight);
    return y;
  }

  try {
    const doc = new jsPDF();
    doc.addFileToVFS('NotoSansSC-Regular.ttf', fontBase64_regular);
    doc.addFileToVFS('NotoSansSC-Bold.ttf', fontBase64_bold);
    doc.addFont('NotoSansSC-Regular.ttf', 'NotoScansSC', 'normal');
    doc.addFont('NotoSansSC-Bold.ttf', 'NotoScansSC', 'bold');
    doc.setFont('NotoScansSC', 'normal');

    const PageWidth = doc.internal.pageSize.getWidth();
    const PageHeight = doc.internal.pageSize.getHeight();

    const pageCenterX = (PageWidth - 3 * pageMargin) / 2;
    const Yoffset = 8;
    const lineHeight = 0.5;
    const tableWidth = PageWidth - 2 * pageMargin;
    const blackColorCode = [0, 0, 0];
    const greyColorCode = [200, 200, 200];
    const yellowColorCode = [255, 255, 0];

    let x1 = pageMargin;
    let y1 = pageMargin;
    let x2 = x1 + pageCenterX + pageMargin;
    let y2 = y1;

    /*//////////////////////////////////////////////////     scienscope logo image      ////////////////////////////////////////////////////*/
    const imgData = new Image();
    imgData.src = scienscopelogo;
    let imageWidth = 32;
    let imageHeight = 8;
    doc.addImage(imgData, 'PNG', PageWidth - pageMargin - imageWidth - 15, y1, imageWidth, imageHeight);

    /*/////////////////////////////////////////////          header for PDF report              //////////////////////////////////////////////////////*/
    doc.setFontSize(14);
    doc.setFont('NotoScansSC', 'bold');
    const Title = region==="TAIWAN"?` 採購訂單 `:" Machine Purchase Order ";
    const title_x = pageCenterX - doc.getTextWidth(Title) / 2 + pageMargin;
    y1 = addTextWithUnderlines(doc, Title, title_x, y1 + 6, doc.getTextWidth(Title) + 2, pageMargin, 1);

    // Draw underline
    y2 = y1;

    const body = [];
    body.push(   ////////////////////////Header//////////////////////////////////////////
      [
        { content: `${scienscopeName}\n${scienscopeAddress}`, colSpan: 3, rowSpan: data.dropShip?5:4, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
        { content: region==="TAIWAN"?"日期: ":"Date: ", colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', lineWidth: 0 } },
        { content: `${PODate}`, colSpan: 2, styles: { halign: 'center', lineWidth: 0 } },
      ],
      [
        { content: region==="TAIWAN"?"訂單號: ":"PO#: ", colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', lineWidth: 0 } },
        { content: `${PONumber}`, colSpan: 2, styles: { halign: 'center', lineWidth: 0 } },
      ],
      [
        { content: region==="TAIWAN"?"發貨方式: ":"Ship Via: ", colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', lineWidth: 0 } },
        { content: `${shippingTerms}`, colSpan: 2, styles: { halign: 'center', lineWidth: 0 } },
      ],   
      [
        { content: data.dropShip?region==="TAIWAN"?"****直送客戶公司":"****Drop Ship":"", colSpan: 3, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },     
      ],   
      [//empty row
        { content: "", colSpan: 6, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
      ],
      [
        { content: region==="TAIWAN"?"供應商: ":"Vendor: ", colSpan: 3, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
        { content: data.dropShip?region==="TAIWAN"?"發送地址: ":"Ship To: ":"", colSpan: 3, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
      
      ],
      [
        { content: `${vendorName}\n${vendorAddress}`, colSpan: 3, styles: { halign: 'left', lineWidth: 0 } },
        { content: data.dropShip?`${customerName}\n${customerAddress}`:"", colSpan: 3, styles: { halign: 'left', lineWidth: 0 } },
      ],
    );
    body.push(
      [//empty row
        { content: "", colSpan: 6, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
      ]
    );
    let index = 1;
    machineinfo.forEach(machine => {
     
      body.push(
        [//empty row
          { content: region==="TAIWAN"?`機器 #${index}`:`Machine #${index}`, colSpan: 6, styles: { halign: 'left', fontStyle: 'bold', fillColor: greyColorCode } },
        ],
        [
          { content: region==="TAIWAN"?"型號: ":"Machine Model: ", colSpan: 2, styles: { halign: 'riht', fontStyle: 'bold', lineWidth: 0 } },
          { content: machine.model, colSpan: 2, styles: { halign: 'left', fontStyle: 'normal', lineWidth: 0 } },
          { content: "", colSpan: 2, styles: { halign: 'riht', fontStyle: 'bold', lineWidth: 0 } },
        ],
      );
      body.push(
        [
          { content: region==="TAIWAN"?"物料號: ":"Item Name:", colSpan: 2, styles: { halign: 'left', fontStyle: 'bold' , lineWidth: 0 } },
          { content: region==="TAIWAN"?"單位價: ":"Unit Price: ", colSpan: 1, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
          { content: region==="TAIWAN"?"數量: ":"Quantity: ", colSpan: 1, styles: { halign: 'left', fontStyle: 'bold' , lineWidth: 0} },  
          { content: region==="TAIWAN"?"描述: ":"Description: ", colSpan: 1, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
          { content: region==="TAIWAN"?"金額: ":"Amount: ", colSpan: 1, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
        ],
      );
      machine.detail.forEach(item => {
        body.push(
          [
            { content: item.item, colSpan: 2, styles: { halign: 'left', fontStyle: 'normal', lineWidth: 0  } },
            { content: `${(machine.machineCustomPrice===true &&parseInt(item.unitprice)===0)?"-":parseFloat(item.unitprice).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`, colSpan: 1, styles: { halign: 'left', fontStyle: 'normal', lineWidth: 0  } },
            { content: item.quantity, colSpan: 1, styles: { halign: 'center', fontStyle: 'normal', lineWidth: 0  } },
            { content: item.description, colSpan: 1, styles: { halign: 'left', fontStyle: 'normal', lineWidth: 0  } },
            { content: `${(machine.machineCustomPrice===true&&parseInt(item.unitprice)===0)?"-":parseFloat(item.unitprice*item.quantity).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`, colSpan: 1, styles: { halign: 'left', fontStyle: 'normal', lineWidth: 0  } },      
          ]
        );
      })
      body.push(
        [
          { content: region==="TAIWAN"?"小計: ":"Sub Total: ", colSpan: 5, styles: { halign: 'right', fontStyle: 'bold', lineWidth: 0 } },
          { content: `${parseFloat(machine.machinePrice).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`, colSpan: 1, styles: { halign: 'left', fontStyle: 'normal', lineWidth: 0.5 } },
        ],
        [
          { content: region==="TAIWAN"?"機器備註: ":"Machine Note: ", colSpan: 6, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
       
        ],
        [
          { content: `${machine.machineNote}`, colSpan: 6, styles: { halign: 'left', fontStyle: 'normal', lineWidth: 0.1 } },
        ]
      );
      index++;
    });
    body.push(
      [//empty row
        { content: "", colSpan: 6, styles: { halign: 'left', fontStyle: 'bold', fillColor: greyColorCode } },
      ],
      [
        { content: "", colSpan: 4, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
        { content: region==="TAIWAN"?"總計: ":"Grand Total: ", colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', lineWidth: 0 } },
        { content: `${parseFloat(price).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`, colSpan: 1, styles: { halign: 'center', fontStyle: 'bold', lineWidth: 0.5 ,fillColor: yellowColorCode} },
      ],
      [
        { content: region==="TAIWAN"?"備註: ":"Note: ", colSpan: 6, styles: { halign: 'left', fontStyle: 'bold', lineWidth: 0 } },
       
      ],
      [
        { content: `${specialRequirement}`, colSpan: 6, styles: { halign: 'left', fontStyle: 'normal', lineWidth: 0.1 } },
      ]
    );
    // Generate the PDF with the autoTable
    doc.autoTable({
      //head: headers,
      body: body,
      columnStyles: {
        0: { cellWidth: tableWidth / 6 },
        1: { cellWidth: tableWidth / 6 },
        2: { cellWidth: tableWidth / 6 },
        3: { cellWidth: tableWidth / 6 },
        4: { cellWidth: tableWidth / 6 },
        5: { cellWidth: tableWidth / 6 },
      },
      theme: 'grid',
      margin: { top: 20 },
      startY: y1 + 10,
      styles: { fontSize: 9, font: 'NotoScansSC', textColor: blackColorCode, cellPadding: 1, lineHeight: 1.2 }, // Set global font size
      didDrawPage: () => {
        // Add footer on each page
        // addFooter(doc, "5751 Schaefer Ave., Chino, CA 91765", formVersion);
      },
    });

    doc.save("machineorder");
  } catch (err) {

  }

}

export default generateMachineOrderPDF;