import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, TextField, Tabs, Grid, Tab, Autocomplete } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useWindowSize from '../../hooks/useWindowSize';
import ErrorDialog from '../ErrorDialog';
import CustomDataGrid from '../CustomDataGrid';
import MobileViewComponent from '../MobileView';
import { useUser } from '../../hooks/UserContext';
import { GetTextByRegion } from '../../hooks/Translation';
import roles from '../../hooks/Roles';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const MachineModelTable = (
  { data, setData,
    racks, setRacks,
    tubes, setTubes,
    detector, setDetector,
    software, setSoftware,
    options, setOptions,
    monitor, setMonitors,
    pc, setPC,
    cabinetassembly, setCabinetAssembly,
    barcodeprinter, setBarcodePrinter,
    barcodescanner, setBarcodeScanner,
    incoterm, setIncoterm,
    paymentmethod, setPaymentMethod,
    shipvia, setShipVia,
    openEditDialog, setOpenEditDialog,
    openDeleteDialog, setOpenDeleteDialog,
    tabValue, setTabValue }) => {

  const [selectedItem, setSelectedItem] = useState({ _id: '', ModelName: '', ForMachine: '' });
  const [error, setError] = useState(null);
  const { windowSize, smallWidth } = useWindowSize();
  const { user: { userRights }, selectedRegion } = useUser();

  const tabTypeMap = {
    0: 'machine',
    1: 'rack',
    2: 'tube',
    3: 'fpd',
    4: 'software',
    5: 'monitor',
    6: 'cabinetassembly',
    7: 'barcodeprinter',
    8: 'barcodescanner',
    9: 'option',
    10: 'incoterm',
    11: 'paymentmethod',
    12: 'shipvia',
    13: 'pc',
    default: 'option',
  };

  const handleDelete = (params) => {
    setSelectedItem(params.row);
    setOpenDeleteDialog(true);
  };

  const handleEdit = (params) => {
    setSelectedItem(params.row);
    setOpenEditDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedItem({ _id: '', ModelName: '' }); // Reset selectedItem
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedItem({ _id: '', ModelName: '' }); // Reset selectedItem
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedItem(prevSelectedItem => ({
      ...prevSelectedItem,
      [name]: value
    }));

  };

  const handleDeleteConfirm = async () => {
    try {
      // Determine type and update function based on tab value
      const type = tabTypeMap[tabValue] || tabTypeMap.default;
      const stateUpdateMap = {
        0: setData,
        1: setRacks,
        2: setTubes,
        3: setDetector,
        4: setSoftware,
        5: setMonitors,
        6: setCabinetAssembly,
        7: setBarcodePrinter,
        8: setBarcodeScanner,
        9: setOptions,
        10: setIncoterm,
        11: setPaymentMethod,
        12: setShipVia,
        13: setPC,
      };

      // Perform delete request
      await axios.delete(`${backendString}/dropdown/${selectedItem._id}?selectedRegion=${selectedRegion}&type=${type}`, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });

      setOpenDeleteDialog(false);

      // Update the corresponding data state
      const updateFunction = stateUpdateMap[tabValue];
      if (updateFunction) {
        updateFunction(prevState => prevState.filter(item => item._id !== selectedItem._id));
      }
    } catch (error) {
      console.error('Error deleting item:', error);
      setError(error.response?.data?.error);
    }
  };

  const handleEditSubmit = async () => {
    try {
      // Determine type based on tab value
      // const type = tabValue === 0 ? 'machine' : tabValue === 1 ? 'tube' : tabValue === 2 ? 'fpd' : tabValue === 3 ? 'software' : 'option';
      const type = tabTypeMap[tabValue] || tabTypeMap.default;
      await axios.put(`${backendString}/dropdown/${selectedItem._id}?selectedRegion=${selectedRegion}&type=${type}`, selectedItem, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });

      setOpenEditDialog(false);

      // Update the correct data array based on tab value
      switch (tabValue) {
        case 0:
          setData(prevData =>
            prevData.map(item => item._id === selectedItem._id ? selectedItem : item)
          );
          break;
        case 1:
          setRacks(prevRacks =>
            prevRacks.map(item => item._id === selectedItem._id ? selectedItem : item)
          );
          break;
        case 2:
          setTubes(prevTubes =>
            prevTubes.map(item => item._id === selectedItem._id ? selectedItem : item)
          );
          break;
        case 3:
          setDetector(prevDetector =>
            prevDetector.map(item => item._id === selectedItem._id ? selectedItem : item)
          );
          break;
        case 4:
          setSoftware(prevSoftware =>
            prevSoftware.map(item => item._id === selectedItem._id ? selectedItem : item)
          );
          break;
        case 5:
          setMonitors(prevMonitors =>
            prevMonitors.map(item => item._id === selectedItem._id ? selectedItem : item)
          )
          break;
        case 6:
          setCabinetAssembly(prevCabinetAssembly =>
            prevCabinetAssembly.map(item => item._id === selectedItem._id ? selectedItem : item)
          )
          break;
        case 7:
          setBarcodePrinter(prevBarcodePrinters =>
            prevBarcodePrinters.map(item => item._id === selectedItem._id ? selectedItem : item)
          )
          break;
        case 8:
          setBarcodeScanner(prevBarcodeScanners =>
            prevBarcodeScanners.map(item => item._id === selectedItem._id ? selectedItem : item)
          )
          break;
        case 10:
          setOptions(prevOptions =>
            prevOptions.map(item => item._id === selectedItem._id ? selectedItem : item)
          );
          break;
        case 10:
          setIncoterm(prevIncoterms =>
            prevIncoterms.map(item => item._id === selectedItem._id ? selectedItem : item)
          );
          break;
        case 11:
          setPaymentMethod(prevPaymentMethods =>
            prevPaymentMethods.map(item => item._id === selectedItem._id ? selectedItem : item)
          );
          break;
        case 12:
          setShipVia(prevShipVia =>
            prevShipVia.map(item => item._id === selectedItem._id ? selectedItem : item)
          );
          break;
        case 13:
          setPC(prevPC =>
            prevPC.map(item => item._id === selectedItem._id ? selectedItem : item)
          );
          break;

        default:
          break;
      }
    } catch (error) {
      console.error('Error editing item:', error);
      setError(error.response?.data?.error);
    }
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getSortedData = (source) =>
    source?.filter(item => item?.ModelName).slice().sort((a, b) => a.ModelName.localeCompare(b.ModelName)) || [];

  const sortedData = (() => {
    switch (tabValue) {
      case 0:
        return getSortedData(data);
      case 1:
        return getSortedData(racks);
      case 2:
        return getSortedData(tubes);
      case 3:
        return getSortedData(detector);
      case 4:
        return getSortedData(software);
      case 5:
        return getSortedData(monitor);
      case 6:
        return getSortedData(cabinetassembly);
      case 7:
        return getSortedData(barcodeprinter);
      case 8:
        return getSortedData(barcodescanner);
      case 9:
        return getSortedData(options);
      case 10:
        return getSortedData(incoterm);
      case 11:
        return getSortedData(paymentmethod);
      case 12:
        return getSortedData(shipvia);
      case 13:
        return getSortedData(pc);
      default:
        return getSortedData(data);
    }
  })();

  const columns = [
    ...(tabValue === 2 || tabValue === 3 || tabValue === 4 || tabValue === 5 || tabValue === 6 || tabValue === 7 || tabValue === 8 || tabValue === 13
      ? [
        {
          field: 'ModelName',
          headerName: GetTextByRegion('Model', selectedRegion),
          flex: 6,
        },
        {
          field: 'ForMachine',
          headerName: GetTextByRegion('For Machine Models', selectedRegion),
          flex: 6,
        },
      ]
      : [
        {
          field: 'ModelName',
          headerName: GetTextByRegion('Model', selectedRegion),
          flex: 6,
        },
      ]),
    {
      field: 'actions',
      headerName: GetTextByRegion('Actions', selectedRegion),
      flex: 1,
      renderCell: (params) =>
        userRights >= roles['MANAGER'] && (
          <div>
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => handleEdit(params)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              color="secondary"
              onClick={() => handleDelete(params)}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ),
    },
  ];


  const mobileColumns = [
    ...(tabValue === 2 || tabValue === 3 || tabValue === 4 || tabValue === 5 || tabValue === 6 || tabValue === 7 || tabValue === 8 || tabValue === 13
      ? [
        { label: GetTextByRegion("Model", selectedRegion), name: "ModelName", type: "text", value: "", required: true, InputLabelProps: {}, position: 'left' },
        { label: GetTextByRegion("For Machine Models", selectedRegion), name: "ForMachine", type: "text", value: "", required: true, InputLabelProps: {}, position: 'left' },
      ]
      : [
        { label: GetTextByRegion("Model", selectedRegion), name: "ModelName", type: "text", value: "", required: true, InputLabelProps: {}, position: 'left' },
      ])
  ];

  return (

    <div style={{ height: windowSize.height * 0.71, width: windowSize.width * 1 }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile>
        <Tab label={GetTextByRegion("Machines", selectedRegion)} />
        <Tab label={GetTextByRegion("Racks", selectedRegion)} />
        <Tab label={GetTextByRegion("Tube", selectedRegion)} />
        <Tab label={GetTextByRegion("Detector", selectedRegion)} />
        <Tab label={GetTextByRegion("Software", selectedRegion)} />
        <Tab label={GetTextByRegion("Monitor", selectedRegion)} />
        <Tab label={GetTextByRegion("Cabinet", selectedRegion)} />
        <Tab label={GetTextByRegion("Printer", selectedRegion)} />
        <Tab label={GetTextByRegion("Scanner", selectedRegion)} />
        <Tab
          label={GetTextByRegion("Options", selectedRegion)}
          sx={{ display: selectedRegion === "CHINA" ? "inherit" : "none" }}
        />
        {/* Show Taiwan tabs, otherwise keep placeholders */}
        <Tab
          label={GetTextByRegion("Incoterm", selectedRegion)}
          sx={{ display: selectedRegion === "TAIWAN" ? "inherit" : "none" }}
        />
        <Tab
          label={GetTextByRegion("PaymentMethod", selectedRegion)}
          sx={{ display: selectedRegion === "TAIWAN" ? "inherit" : "none" }}
        />
        <Tab
          label={GetTextByRegion("ShipVia", selectedRegion)}
          sx={{ display: selectedRegion === "TAIWAN" ? "inherit" : "none" }}
        />
        <Tab label={GetTextByRegion("PC", selectedRegion)} />
      </Tabs>

      {windowSize.width < smallWidth ? (
        <MobileViewComponent items={sortedData} columnData={mobileColumns} handleEditClick={handleEdit} handleDelete={handleDelete} />
      ) : (
        <CustomDataGrid
          rows={sortedData}
          columns={columns}
        />

      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>{GetTextByRegion("Delete Model", selectedRegion)}</DialogTitle>
        <DialogContent>
          {GetTextByRegion("Are you sure you want to delete this model?", selectedRegion)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>{GetTextByRegion("Cancel", selectedRegion)}</Button>
          <Button onClick={handleDeleteConfirm} variant="contained" color="secondary">{GetTextByRegion("Delete", selectedRegion)}</Button>
        </DialogActions>
      </Dialog>

      {/* Edit machine model Dialog */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>{GetTextByRegion("Edit Model", selectedRegion)}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} justifyContent="center" alignItems="center" mt={0}>
            <Grid item xs={10}>
              <TextField
                label={GetTextByRegion("Model Name", selectedRegion)}
                variant="outlined"
                name="ModelName"
                value={selectedItem.ModelName || ''}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                type="text"
                sx={{ mt: 1 }}
              />
            </Grid>
            <Grid item xs={10}>
              {
                (tabValue === 2 || tabValue === 3 || tabValue === 4 || tabValue === 5 || tabValue === 6 || tabValue === 7 || tabValue === 8 || tabValue === 13) && (
                  <Autocomplete
                    fullWidth
                    multiple
                    options={data}
                    getOptionLabel={(option) => option.ModelName}
                    value={selectedItem.ForMachine ? data.filter(item => selectedItem.ForMachine.split(',').includes(item.ModelName)) : []}
                    onChange={(event, newValue) => {
                      handleInputChange({
                        target: {
                          name: "ForMachine",
                          value: newValue.map(item => item.ModelName).toString(),
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={GetTextByRegion("For Machine Models", selectedRegion)}
                        variant="outlined"
                        name="ForMachine"
                      />
                    )}
                    isOptionEqualToValue={(option, value) => option._id === value?._id}
                  />
                )
              }
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="secondary" >{GetTextByRegion("Cancel", selectedRegion)}</Button>
          <Button onClick={handleEditSubmit} variant="contained" color="primary">{GetTextByRegion("Save", selectedRegion)}</Button>
        </DialogActions>
      </Dialog>
      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </div>
  );
};

export default MachineModelTable;
