import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid, FormControlLabel, TextField, Box, CircularProgress, Tooltip, Typography, TableHead, TableBody, TableCell, TableRow, Table, IconButton, Checkbox } from '@mui/material';
import ViewListIcon from '@mui/icons-material/ViewList';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';
import AddMachineForm from './AddMachineForm';
import FormControl from '@mui/material/FormControl';
import EditMachineForm from './EditMachineForm';
import MachineModelDropdown from '../ChinaProduction/MachineModelDropdown';


const backendString = process.env.REACT_APP_BACKEND_STRING;
const PurchaseOrder = ({ onOpen, handleAddMachine = null, onClose, type = 'both', isEdit = false, selectedItem, onSave = null }) => {
    const today = new Date().toISOString().split('T')[0]; //get today's date
    const { selectedRegion, user: { username, email } } = useUser();
    const [validationErrors, setValidationErrors] = useState({});
    const [addMachineView, setAddMachineView] = useState(false);
    const [editMachineView, setEditMachineView] = useState(false);
    const [loading, setLoading] = useState(false);
    const [CFOEmail, setCFOEmail] = useState("");
    const [machines, setMachines] = useState([]);   //machines in this order
    const [selectedMachine, setSelectedMachine] = useState();
    const [dropShip, setDropShip] = useState(false);

    const machineOrderTemplate = {
        ponumber: '',
        customername: '', //company name
        customeraddress: '',//company address  
        customercontactperson: '',//customer contact name     
        customercontactphone: '',//customer contact phone
        vendorname: selectedRegion === 'TAIWAN' ? '善思光电科技有限公司' : '',
        vendoraddress: selectedRegion === 'TAIWAN' ? '江蘇省崑山市經濟技術開發區琵琶路28號' : '',
        podate: today,
        specialrequirement: '',
        orderStatus: 'NEW',
        owner: username,
        owneremail: email,
        region: selectedRegion,
        orderprice: 0,
        shipVia: '',
    }

    //cells for machine information
    const [hiddenCells, setHiddenCells] = useState({});

    //toggle machine information cells
    const toggleHiddenCell = (id) => {
        console.log(machines);
        setHiddenCells((prevState) => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    //all require fields 
    const requiredFields = [];
    //validate the form
    const validateForm = () => {
        const errors = {};
        requiredFields.forEach(field => {
            if (!formData[field]) {
                errors[field] = `this field is required`;
            }
        });
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const [formData, setFormData] = useState(machineOrderTemplate)

    //when the machine data is changed, recalculate the total
    useEffect(() => {
        // console.log("Machines updated:", machines);
        let total = machines.reduce((sum, machine) => sum + machine.machinePrice, 0);
        setFormData(prevFormData => ({
            ...prevFormData,
            orderprice: total
        }));
    }, [machines]);

    //if in the edit mode
    useEffect(() => {
        if (isEdit && selectedItem) {
            setFormData(selectedItem);
            setDropShip(selectedItem.dropShip);
            setMachines(selectedItem.machines);
        }
        getCFOEmail();
    }, [isEdit, selectedItem]);

    //get CFO's emial
    const getCFOEmail = async () => {
        const response = await axios.get(backendString + '/users/email/CFO_EMAIL');
        setCFOEmail(response.data.email);
    }

    //set field valus to default
    const resetFormData = () => {
        setFormData(machineOrderTemplate);
    }
    //field valus change event
    const handleChange = (event) => {
        const { name, value } = event.target;
        let newValue = value;
        let fieldName = name;

        setFormData((prev) => ({ ...prev, [fieldName]: newValue, }));
        if (validationErrors[name]) {
            setValidationErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };
    //drop ship check box event 
    const handleDropShipChange = (event) => {
        setDropShip(event.target.checked);
    }
    //open the add machine window
    const openAddMachine = () => {
        setAddMachineView(true);
    }
    //close the add machine window
    const closeAddMachine = () => {
        setAddMachineView(false);
    }
    //add new machine to this order
    const addMachineToOrder = (newMachine) => {

        setMachines(prevMachines => [
            ...prevMachines,
            {
                id: newMachine.id,
                model: newMachine.model,
                machineCustomPrice: newMachine.machineCustomPrice,
                machinePrice: newMachine.machinePrice,
                machineNote: newMachine.machineNote,
                detail: newMachine.parts
            }
        ]);
        closeAddMachine();
    }
    //
    const handleEditMachine = (index) => {
        setSelectedMachine(machines[index]);
        openEditMachineView();
    }
    //edit a machines
    const editMachineToOrder = (updatedMachine) => {
        const newMachines = machines.map(machine =>
            machine.id === updatedMachine.id ? updatedMachine : machine
        );
        setMachines(newMachines);
        closeEditMachineView();
    }

    //delete the machine from machine order form
    const handleDeleteMachine = (index) => {
        setMachines(machines.filter((_, i) => i !== index));
    }
    //place a new order
    const handleSubmit = async () => {
        //console.log("submit called to add record");
        //if (!validateForm()) {
        //    return;
        //}
        let newpo = '';
        let region = 'PUS';
        switch (selectedRegion) {
            case 'USA':
                region = 'PUS';
                break;
            case 'CHINA':
                region = 'PCN';
                break;
            case 'MEXICO':
                region = 'PMX';
                break;
            case 'EU':
                region = 'PEU';
                break;
            case 'TAIWAN':
                region = 'PTW';
                break;
            default:
                region = 'PUS';
                break;
        }
        let updatedFormData = null;
        //setLoading(true);
        try {
            //get temp PO number
            let response = await axios.post(`${backendString}/newPO/`);
            newpo = response.data.poNumber;
            updatedFormData = { ...formData, ponumber: newpo, machines: machines, dropShip: dropShip };
            setFormData(updatedFormData);

        } catch (error) {
            console.error("Error Creating ponumber...", error);
            return;
        }
        //console.log("uoload data: ", updatedFormData);
        try {
            // add new machine order to database
            const response = await axios.post(`${backendString}/machineorder?region=${selectedRegion}`,
                updatedFormData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': sessionStorage.getItem('token') || "",
                    },
                }
            );
            //add machine order to table
            handleAddMachine(response.data.data);

        } catch (error) {
            console.error("Error submitting machine order:", error);
            return;
        }
        // // send email to CFO to notify CFO of new order
        try {
            await axios.post(backendString + `/machineorder/send-email/sent`, {
                "POnumber": updatedFormData.ponumber,
                "Email": CFOEmail,
            }, {
                headers: {
                    'Authorization': sessionStorage.getItem('token')
                }
            });

        } catch (error) {
            console.error("Error sending email to CFO!:", error);
            return;
        }
        setLoading(false);
        onClose();
    };

    //loading window
    const LoadingDialog = ({ open }) => {
        return (
            <Dialog open={open}>
                <DialogContent
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "20px",
                    }}
                >
                    <CircularProgress />
                    <Typography style={{ marginTop: "10px" }}>{GetTextByRegion("Sending data....Please wait", selectedRegion)}</Typography>
                </DialogContent>
            </Dialog>
        );
    }

    //update the machine order record in DB
    const handleEdit = async () => {
        setLoading(true);
        formData.orderStatus = "Edited";
        // console.log(formData);
        formData.machines !== null ? formData.machines = machines : formData.machines = [];
        try {
            var response;
            response = await axios.put(`${backendString}/machineorder/${formData._id}?region=${selectedRegion}`, formData, {
                headers: {
                    'Authorization': sessionStorage.getItem('token')
                }
            });
            onSave(response.data);
            onClose();
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };


    //text field
    const TextFieldTemplate = (fieldName, headerName, width = 6, row = 1, type = "text", variant = "outlined", multiline = false) => {
        return (
            <Grid item xs={width}>
                <TextField
                    fullWidth
                    required={requiredFields.includes(fieldName)}
                    error={!!validationErrors[fieldName]}
                    helperText={validationErrors[fieldName] || ""}
                    multiline={multiline}
                    rows={row > 1 ? row : null}
                    label={GetTextByRegion(headerName, selectedRegion)}
                    variant={variant}
                    value={formData[fieldName]}
                    name={fieldName}
                    onChange={handleChange}
                    type={type}
                    InputLabelProps={
                        type === "date" ? { shrink: true } : undefined
                    }
                    disabled={fieldName === "owner" || fieldName === "region" || fieldName === "owneremail"}
                />
            </Grid>
        );
    }
    //display machine parts and information
    const getTotal = (detail) => {
        let total = 0;
        detail.forEach(item => {
            let unitPrice = Number(item.unitprice);
            total += (unitPrice * item.quantity);
        });
        return total;
    }

    //open the edit part view
    const openEditMachineView = () => {
        setEditMachineView(true);
    }
    //close the edit part view
    const closeEditMachineView = () => {
        setEditMachineView(false);
    }

    return (
        <Dialog open={onOpen} onClose={onClose} >
            <DialogTitle>{GetTextByRegion("Place a Machine Order", selectedRegion)}{isEdit ? `: ${selectedItem.ponumber}` : ''} </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} justifyContent="left" alignItems="center" mt={0}>
                    {
                        TextFieldTemplate('podate', 'Application Date', 6, 1)
                    }
                    {
                        <Grid item xs={6}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-amount">
                                    {GetTextByRegion("Total Price", selectedRegion)}
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    onChange={handleChange}
                                    disabled={true}
                                    value={formData.orderprice}
                                    label="orderprice"
                                    name='orderprice'
                                />
                            </FormControl>
                        </Grid>
                    }
                    {
                        TextFieldTemplate('vendorname', 'Vendor', 6, 1, "text", 'outlined', true)
                    }
                    {
                        TextFieldTemplate('vendoraddress', 'Vendor Address', 6, 1, "text", 'outlined', true)
                    }
                    {
                        <Grid item xs={6}>
                            <MachineModelDropdown
                                selectedRegion={selectedRegion}
                                GetTextByRegion={GetTextByRegion}
                                handleChange={handleChange}
                                validationErrors={validationErrors}
                                machine={formData}
                                type="shipvia" // Set to "option"
                            />
                        </Grid>
                    }
                    {
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="dropShip"
                                        color="primary"
                                        checked={dropShip}
                                        onChange={(e) => handleDropShipChange(e)}
                                    />
                                }
                                label={GetTextByRegion("Drop Ship", selectedRegion)}
                            />
                        </Grid>
                    }
                    {
                        dropShip === false ? (
                            <></>
                        ) : (
                            <>
                                {
                                    TextFieldTemplate('customername', 'Company', 6, 1, "text", "outlined", true)
                                }
                                {
                                    TextFieldTemplate("customeraddress", "Customer Address", 6, 1, "text", "outlined", true)
                                }
                                {
                                    TextFieldTemplate("customercontactperson", "Contact Person", 6, 1)
                                }
                                {
                                    TextFieldTemplate("customercontactphone", "Contact Phone #", 6, 1)
                                }
                            </>
                        )
                    }
                    {

                        TextFieldTemplate('specialrequirement', 'Notes', 12, 4, "text", "outlined", true)
                    }
                    <Grid item xs={12} container justifyContent="flex-start">
                        <Button variant="contained" color="primary" onClick={openAddMachine} style={{ marginLeft: 8 }}>
                            {GetTextByRegion("Add Machine", selectedRegion)}
                        </Button>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>{GetTextByRegion("Model", selectedRegion)}</TableCell>
                                    <TableCell>{GetTextByRegion("Price", selectedRegion)}</TableCell>
                                    <TableCell>{GetTextByRegion("Notes", selectedRegion)}</TableCell>
                                    <TableCell>{GetTextByRegion("", selectedRegion)}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {machines.map((row, index) => (
                                    <React.Fragment key={index}>
                                        {/* main rows */}
                                        <TableRow>
                                            <TableCell>{index}</TableCell>
                                            <TableCell>{row.model}</TableCell>
                                            <TableCell>{parseFloat(row.machinePrice).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</TableCell>
                                            <TableCell>{row.machineNote}</TableCell>
                                            <TableCell>
                                                <Box display="flex">  {/* Use Box to wrap and control spacing */}
                                                    <Tooltip title={GetTextByRegion("Detail", selectedRegion)} arrow>
                                                        <IconButton aria-label="Details" color="success" onClick={() => toggleHiddenCell(index)}>
                                                            <ViewListIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={GetTextByRegion("Edit", selectedRegion)} arrow>
                                                        <IconButton aria-label="Edit" color="primary" onClick={() => handleEditMachine(index)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={GetTextByRegion("Delete", selectedRegion)} arrow>
                                                        <IconButton aria-label="delete" color="secondary" onClick={() => handleDeleteMachine(index)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                        {/* hidden cell */}
                                        {hiddenCells[index] && (
                                            <TableRow>
                                                <TableCell colSpan="5" style={{ backgroundColor: '#f9f9f9' }}>
                                                    {GetTextByRegion("Machine Information", selectedRegion)}:
                                                    <Table size="small" style={{ marginTop: '8px' }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>{GetTextByRegion("Item", selectedRegion)}</TableCell>
                                                                <TableCell>{GetTextByRegion("Quantity", selectedRegion)}</TableCell>
                                                                <TableCell>{GetTextByRegion("Description", selectedRegion)}</TableCell>
                                                                <TableCell>{GetTextByRegion("Unit Price", selectedRegion)}</TableCell>
                                                                <TableCell>{GetTextByRegion("Cost", selectedRegion)}</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {row.detail.map((item, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>{item.item}</TableCell>
                                                                    <TableCell>{item.quantity}</TableCell>
                                                                    <TableCell>{item.description}</TableCell>
                                                                    <TableCell align="right">
                                                                        {row.machineCustomPrice === true && parseInt(item.unitprice) === 0? "-": parseFloat(item.unitprice).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}                                                                                
                                                                    </TableCell>
                                                                    <TableCell align="right">{row.machineCustomPrice === true && parseInt(item.unitprice) === 0?"-":parseFloat(item.unitprice * item.quantity).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                            <TableRow>
                                                                <TableCell colSpan={5} align="right" style={{ fontWeight: "bold" }}>
                                                                    {GetTextByRegion("Sub Total", selectedRegion)}: {parseFloat(getTotal(row.detail)).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                        <br />
                        <Grid item xs={12}>
                            <Box style={{ marginTop: 16, textAlign: 'right' }}>
                                <strong>{GetTextByRegion("Machine Grand Total", selectedRegion)}:  </strong>
                                <strong>{parseFloat(formData.orderprice).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</strong>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container justifyContent="flex-end">
                        <Button variant="outlined" color="secondary" onClick={() => { onClose(); resetFormData(); }}>
                            {GetTextByRegion("Close", selectedRegion)}
                        </Button>
                        {!isEdit ?
                            (
                                <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginLeft: 8 }}>
                                    {GetTextByRegion("Submit", selectedRegion)}
                                </Button>
                            )
                            :
                            (
                                <Button variant="contained" color="primary" onClick={handleEdit} style={{ marginLeft: 8 }}>
                                    {GetTextByRegion("Edit", selectedRegion)}
                                </Button>
                            )
                        }

                    </Grid>
                </Grid>
            </DialogContent>
            {addMachineView && <AddMachineForm open={true} onClose={closeAddMachine} addMachine={addMachineToOrder} />}
            {editMachineView && <EditMachineForm open={editMachineView} onClose={closeEditMachineView} onEdit={editMachineToOrder} machine={selectedMachine} />}
            {loading && <LoadingDialog open={loading} />}

        </Dialog >
    );
};

export default PurchaseOrder;
