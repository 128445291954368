//this window is for China to approve the machine orders
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField, Box, CircularProgress, Typography } from '@mui/material';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';
import ErrorDialog from '../ErrorDialog';

const PurchaseOrderConfirmView = ({ onOpen, onClose, selectedItem, onDelete }) => {
    const { selectedRegion } = useUser();
    const machineOrder = selectedItem;
    const [machineSerials, setMachineSerials] = useState([]);
    const backendString = process.env.REACT_APP_BACKEND_STRING || '';
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [chinaProductionEmail, setChinaProductionEmail] = useState("");
    const [loading, setLoading] = useState(false);

    //init
    useEffect(() => {
        if (machineOrder?.machineSN) {
            const machineSNArray = machineOrder.machineSN.split(",");
            setMachineSerials(machineSNArray);
        }
        getChinaProductionEmail();
    }, [machineOrder]);

    //get 
    const getChinaProductionEmail = async () => {
        const response = await axios.get(backendString + '/users/email/CHINA_PRODUCTION_EMAIL');
        setChinaProductionEmail(response.data.email);
    }

    //create a machine chinaproduction if it has serial and not duplicated
    const handleSave = async () => {

        // Validate input
        if (machineSerials.length !== selectedItem.quantity) {
            setErrorMessage("Please fill the serial numbers");
            handleOpenErrorDialog();
        } else {

            // Prepare serial values as a string and array
            const serialValuesString = machineSerials
                .map(item => (item && item.serialValue) || '')
                .join(',');
            machineOrder.machineSN = serialValuesString;

            const machineSNArray = machineOrder.machineSN.split(',').filter(Boolean);

            let errorMessages = [];
            setLoading(true);
            //check existance of each machine serial
            for (const machineSN of machineSNArray) {
                const response = await axios.get(backendString + `/chinaProduction/exist/` + machineSN);    //returns json message:Yes / No
                if (response.data["message"] === "Yes") {
                    setErrorMessage("Duplicated machine serial number found!");
                    handleOpenErrorDialog();
                    setLoading(false);
                    return; //return if it found
                }
            }
            //inputing new machine to china production with serials
            for (const machineSN of machineSNArray) {
                try {
                    //china production data
                    const data = {
                        machineModelAndConfig: machineOrder.machinemodel,
                        machineNo: machineSN,
                        customer: machineOrder.customername,
                        orderReceivedDate: machineOrder.applicationdate,
                        leadTimeOrDeliveryDate: machineOrder.expecteddeliverydate,
                        readyShipDate: "",
                        actualShipDate: "",
                        tube: machineOrder.tube,
                        detector: machineOrder.detector,
                        pc: machineOrder.pc,
                        monitor: machineOrder.monitor,
                        status: "Incoming materials pending",
                        financialRelease: "",
                        specialConfiguration: "",
                        poNumber: machineOrder.poNumber,
                        options: "",
                        software: "",
                        stage: "Production",
                        regionTo: selectedRegion === "CHINA" ? "China" : selectedRegion === "EU" ? "EU" : selectedRegion === "TAIWAN" ? "China" : "Overseas",  // Handle Region spelling for ChinaProduction (view App.js file)
                        warranty: "",
                    }
                    //create a new production machine
                    await axios.post(backendString + `/chinaproduction?`,
                        data, {
                        headers: {
                            'Authorization': sessionStorage.getItem('token')
                        }
                    });
                    //console.log('Machine created successfully:', response.data);
                } catch (error) {
                    //push the error message
                    errorMessages.push(`${error.response?.data?.error || 'Error'}: ${machineSN}`);
                }
            }


            //remind China production and sender
            await axios.post(backendString + `/machineorder/send-email/approved`, {
                "POnumber": machineOrder.ponumber,
                "ChinaEmail": chinaProductionEmail,
                "SenderEmail": machineOrder.owneremail,
            }, {
                headers: {
                    'Authorization': sessionStorage.getItem('token')
                }
            });

            //Handle the result
            if (errorMessages.length > 0) {
                setErrorMessage(errorMessages);
                handleOpenErrorDialog();
            } else {
                //onDelete(); //*require selectedItem and selectedRegion in this file
                updateStatus();
                onClose();
            }
        }
    };

     //update the record in DB
     const updateStatus = async () => {
        selectedItem.orderStatus = "Submitted";
        try {
            var response;
            response = await axios.put(`${backendString}/machineorder/${ selectedItem._id}?region=${selectedRegion}`,  selectedItem, {
                headers: {
                    'Authorization': sessionStorage.getItem('token')
                }
            });
        } catch (error) {
        }
    };

    const handleOpenErrorDialog = () => {
        setOpenErrorDialog(true);
    }
    const handleCloseErrorDialog = () => {
        setErrorMessage("");
        setOpenErrorDialog(false);
    }

    const LoadingDialog = ({ open }) => {
        return (
            <Dialog open={open}>
                <DialogContent
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "20px",
                    }}
                >
                    <CircularProgress />
                    <Typography style={{ marginTop: "10px" }}>{GetTextByRegion("Sending data....Please wait",selectedRegion)}</Typography>
                </DialogContent>
            </Dialog>     
        );
    }

    return (
        <Dialog open={onOpen} onClose={onClose}>
            <DialogTitle>{GetTextByRegion("Machine Order Confirmation", selectedRegion)}</DialogTitle>
            <DialogContent>
                {GetTextByRegion("Please Fill all serial number", selectedRegion)}
                <Grid container spacing={1}>
                    {Array.from({ length: machineOrder?.quantity || 0 }).map((_, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={12} key={`divider-${index}`}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
                                    {GetTextByRegion("Machine", selectedRegion) + ` -${index + 1}`}
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ marginTop: "16px" }}>
                                <TextField
                                    label={GetTextByRegion(`Machine Model`, selectedRegion)}
                                    variant="standard"
                                    value={machineOrder.machinemodel}
                                    InputProps={{
                                        disableUnderline: true,
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label={GetTextByRegion("Machine Serial", selectedRegion)}
                                    variant="outlined"
                                    margin="normal"
                                    name={`Serial-${index}`}
                                    value={machineSerials[index]?.serialValue || ''}
                                    onChange={(e) => {
                                        const newSerials = [...machineSerials];
                                        newSerials[index] = {
                                            serialID: `Serial-${index}`,
                                            serialValue: e.target.value
                                        }
                                        setMachineSerials(newSerials); // Update the state                                   
                                    }}
                                />
                            </Grid>
                        </React.Fragment>
                    ))}
                    <Grid item xs={12} container justifyContent="flex-end">
                        <Button variant="contained" color="primary" onClick={() => { handleSave(); }} style={{ marginRight: 8 }}>
                            {GetTextByRegion("Confirm and Create", selectedRegion)}
                        </Button>
                        <Button variant="text" color="secondary" onClick={() => { onClose(); }} style={{ marginRight: 8 }}>
                            {GetTextByRegion("Close", selectedRegion)}
                        </Button>
                    </Grid>
                </Grid>
                {openErrorDialog && (<ErrorDialog open={openErrorDialog} onClose={handleCloseErrorDialog} errorMessage={errorMessage} />)}
                {loading && <LoadingDialog open={loading} />}
            </DialogContent>

        </Dialog>

    );

}

export default PurchaseOrderConfirmView;