import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { Button, IconButton, Box, Typography, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorDialog from '../ErrorDialog';
import afterSaleColumnData from '../AfterSales/AfterSalesColumns';
import SearchBar from '../SearchBar';
import useWindowSize from '../../hooks/useWindowSize';
import roles from '../../hooks/Roles';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import Papa from 'papaparse';
import AfterSalesForm from './AfterSalesForm';
import MobileViewComponent from '../MobileView';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';
import CustomDataGrid from '../CustomDataGrid';
const backendString = process.env.REACT_APP_BACKEND_STRING;


const AfterSaleTable = (tabRegion) => {
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openErrorDialog, setErrorDialog] = useState(false);

  const [isAfterSalePopupOpen, setIsAfterSalePopupOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState(data);
  const { windowSize, smallWidth } = useWindowSize();
  const { user: { userRights }, selectedRegion, refreshUser } = useUser();
  const [aftersalesColumnVisibilityConfig, setAftersalesColumnVisibilityModel] = useState();


  //load customized columns settins
  const LoadColumns = () => {
    let tableName = "aftersale";
    return axios
      .get(`${backendString}/loadTableColumns?tableName=${tableName}`, {
        headers: {
          Authorization: sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        const columns = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
        return columns;
      })
      .catch((error) => {
        console.error("Error loading columns:", error);
        return {};
      });
  };

  //init columns
  useEffect(() => {
    const Load = async () => {
      try {
        const columns = await LoadColumns();
        setAftersalesColumnVisibilityModel(columns);
      } catch (error) {
        console.log("error");
      }
    }
    Load();
  }, []);

  //handle the delete the item event
  const handleDelete = (params) => {
    setSelectedItem(params.row);
    setOpenDeleteDialog(true);
  };

  //handle the edit item event 
  const handleEdit = (params) => {
    setSelectedItem(params.row);
    setOpenEditDialog(true);
  };

  //close the delete dialog
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedItem({ _id: '' }); // Reset selectedItem
  };
  //close the Edit dialog
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedItem({ _id: '' }); // Reset selectedItem
  };

  //delete event after a user confirmed
  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`${backendString}/aftersales/${selectedItem._id}?region=${tabRegion.tabRegion.region}`, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
      setData(prevData =>
        prevData.filter(item => item._id !== selectedItem._id)
      );
      setOpenDeleteDialog(false);
    } catch (error) {
      setErrorMessage(error.response?.data?.error || "Error deleting after sales record");
      handleOpenErrorDialog();
    }
  };

  const handleSubmitForm = (newData) => {
    setData([...data, newData]);
    setIsAfterSalePopupOpen(false);
  }
  //update the edit item
  const handleEditSave = (modifiedData) => {
    setData(prevData =>
      prevData.map(item =>
        item._id === modifiedData._id ? modifiedData : item
      )
    );
    handleCloseEditDialog();
  };

  const handleOpenErrorDialog = () => {
    setErrorDialog(true);
  };

  const handleCloseErrorDialog = () => {
    setErrorDialog(false);
    setErrorMessage('');
  };

  const fileInputRef = useRef(null);

  const handleImportCSV = () => {
    fileInputRef.current.click(); // Trigger file input click

  };
  const handleFileInputChange = (event) => {
    const file = event.target.files[0]; // Get the selected file       
    importAfterSaleCSV(file); // Pass the file to the importCSV function
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(`${backendString}/aftersales?region=${tabRegion.tabRegion.region}`);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching after sales records:', error);
      setErrorMessage("Error fetching after sales records");
      handleOpenErrorDialog();
    }
  }, [setData, tabRegion]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    refreshUser();
  }, [refreshUser, isAfterSalePopupOpen, openDeleteDialog, openEditDialog]);

  useEffect(() => {
    setFilteredItems(data.filter(item =>
      (item.ordernumber && item.ordernumber.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (item.customername && item.customername.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (item.machineID && item.machineID.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (item.machinemodel && item.machinemodel.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (item.materialname && item.materialname.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (item.materialnumber && item.materialnumber.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (item.region && item.region.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (item.trackingnumber && item.trackingnumber.toLowerCase().includes(searchQuery.toLowerCase()))
    ));
  }, [searchQuery, data]);

  //import csv
  const importAfterSaleCSV = async (csvFile) => {
    if (!csvFile) {
      console.error('No file selected');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('csvFile', csvFile); // Append the CSV file to FormData
      await axios.post(`${backendString}/aftersales/import?region=${tabRegion.tabRegion.region}`, formData, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });

      fetchData(); // Update items after successful import
    } catch (error) {
      console.error('Error importing items:', error);
      setErrorMessage('Error importing items');
      handleOpenErrorDialog();
    }
  };

  //headers
  const tempColumns = afterSaleColumnData.map(column => (
    {
      field: column.name, flex: column.flex,
      headerAlign: 'center',
      align: 'center',
      minWidth: 160,
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion(column.label, selectedRegion)}</Typography>
      )
      ,
      renderCell: (params) => {
        if (column.name === "recyclestatus") {
          return (params.value ? GetTextByRegion('Yes', selectedRegion) : GetTextByRegion('No', selectedRegion));
        }
        return params.value;
      }
    }
  ));

  const columns = [...tempColumns,
  {
    field: 'actions',
    headerName: 'Actions',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 160,
    renderHeader: () => (
      <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion("Actions", selectedRegion)}</Typography>
    ),
    renderCell: (params) => (
      /* Edit and delete Item Dialog */
      userRights >= roles['MODERATOR'] && selectedRegion === 'CHINA' && (
        <div>
          <Tooltip title={GetTextByRegion("Edit", selectedRegion)} anrrow>
            <IconButton aria-label="edit" color="primary" onClick={() => handleEdit(params)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          {userRights >= roles['MANAGER'] && (
            <Tooltip title={GetTextByRegion("Delete", selectedRegion)} anrrow>
              <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(params)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      )
    ),

    headerAlign: 'center', // Center header text for Actions column as well   

  },
  ];

  //export data 
  const exportToCSV = () => {
    // Map the items to exclude the _id field and ensure all fields are present
    const dataToExport = filteredItems.map(item => {
      const { _id, ...rest } = item;
      // Ensure all fields are included with default values if they are empty or missing
      return {
        applicationdate: rest.applicationdate || '',
        ordernumber: rest.ordernumber || '',
        customername: rest.customername || '',
        machineID: rest.machineID || '',
        machinemodel: rest.machinemodel || '',
        materialname: rest.materialname || '',
        materialnumber: rest.materialnumber || '',
        requestedquantity: rest.requestedquantity || 0,
        chargestatus: rest.chargestatus || '',
        requestreason: rest.requestreason || '',
        region: rest.region || '',
        shippedquantity: rest.shippedquantity || 0,
        deliverynote: rest.deliverynote || '',
        trackingnumber: rest.trackingnumber || '',
        dispatchdate: rest.dispatchdate || '',
        recyclestatus: rest.recyclestatus || false,
        returneddate: rest.returneddate || '',
        warehousereceiptnumber: rest.warehousereceiptnumber || '',
        remarks: rest.remarks || '',
      };
    });
    // Convert data to CSV format
    const csvData = Papa.unparse(dataToExport);

    // Create a Blob from the CSV data
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

    // Create a link element for downloading the CSV file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    const now = new Date();
    const formattedDate = String(now.getMonth() + 1).padStart(2, '0') + '/' +
      String(now.getDate()).padStart(2, '0') + '/' + now.getFullYear();
    link.download = 'Part Requset Log ' + formattedDate + ' (' + tabRegion.tabRegion.region + ').csv';
    // Programmatically click the link to trigger the download
    link.click();
  }

  //PC view
  const renderDataGrid = () => (
    <Box style={{ height: windowSize.height * 0.77 }}>
      <CustomDataGrid rows={filteredItems} columns={columns} visibilityColumnConfig={aftersalesColumnVisibilityConfig} setColumnVisibility={setAftersalesColumnVisibilityModel} tableName={'aftersale'} />
    </Box>
  );

  return (
    <div>
      {selectedRegion === "CHINA" && userRights >= roles['MODERATOR'] && (
        <Button variant="contained" color="primary" onClick={() => setIsAfterSalePopupOpen(true)}>
          {GetTextByRegion("Add New Record", selectedRegion)}
        </Button>
      )}

      {isAfterSalePopupOpen &&
        <AfterSalesForm openDialog={isAfterSalePopupOpen} onSave={handleSubmitForm} onClose={() => setIsAfterSalePopupOpen(false)} tabRegion={tabRegion} />
      }
      <div style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: !windowSize.width > smallWidth ? 'flex-end' : 'center',
        flexDirection: windowSize.width <= smallWidth ? 'column' : 'row', // Column for mobile, row for larger screens
      }}>
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} placeholder={GetTextByRegion('Search Data...', selectedRegion)} />
        {userRights >= roles['SERVICE'] && (
          <div style={{
            display: 'flex',
            flexDirection: 'row', // Stack buttons in column for mobile
            position: windowSize.width > smallWidth ? 'absolute' : 'relative', // Absolute on larger screens
            left: windowSize.width > smallWidth ? 0 : 'auto', // Align left on larger screens
          }}>
            <Button color="primary" component="span" onClick={exportToCSV}>
              {GetTextByRegion("Export to CSV", selectedRegion)}
            </Button>
            {/* Hidden file input */}
            {selectedRegion === "CHINA" && (
              <>
                <input
                  type="file"
                  accept=".csv"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileInputChange} // Handle CSV import when file is selected
                  id="csv-upload"
                />
                {userRights >= roles['ADMIN'] && (
                  <Button color="primary" component="span" onClick={handleImportCSV}>
                    {GetTextByRegion("Import CSV", selectedRegion)}
                  </Button>
                )}
              </>
            )}
          </div>
        )}
      </div>

      {/*Display view */}
      {windowSize.width > smallWidth ? renderDataGrid() : <MobileViewComponent items={filteredItems} columnData={afterSaleColumnData} handleEditClick={handleEdit} handleDelete={handleDelete} selectedRegion={selectedRegion} />}

      {openEditDialog && (
        <AfterSalesForm openDialog={openEditDialog} onClose={handleCloseEditDialog} selectedItem={selectedItem} isEdit={true} onSave={handleEditSave} tabRegion={tabRegion} />)
      }
      {/* Delete Item Dialog */}
      {openDeleteDialog && (
        <DeleteConfirmationDialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} onConfirm={handleDeleteConfirm} selectedRegion={selectedRegion} />)
      }
      <ErrorDialog open={openErrorDialog} onClose={handleCloseErrorDialog} errorMessage={errorMessage} />
    </div>
  );
};

export default AfterSaleTable;

