import React from 'react';
import AfterSalesTable from '../components/AfterSales/AfterSalesTable';
import { useUser, useValidateRegions } from '../hooks/UserContext';


function AfterSalesView (region) {
    const validateSelectedRegion = ["CHINA", "USA", "EU"];
    const {selectedRegion } = useUser();
    useValidateRegions(selectedRegion,validateSelectedRegion);
    return (
      <div>
        <AfterSalesTable tabRegion={region}/> 
      </div>
    );
};

export default AfterSalesView;