import { useState, useEffect } from "react";
import axios from 'axios';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, TextField, Autocomplete, Grid } from "@mui/material";
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';

const backendString = process.env.REACT_APP_BACKEND_STRING;


const EditMachinePart =({open,onClose,part,onEdit})=>{

    const [currentPart,setCurrentPart] = useState(part);
    const { selectedRegion, user } = useUser();
    const [errorMessage, setErrorMesage] = useState("");
    const [opt_category, setOptionCategory] = useState(["Chasis", "Software", "PC", "Monitor", "X-Ray Tube", "Detector", "Printer", "Scanner"]);
    const [opt_models, setModels] = useState([]);
    const [opt_chasis, setChasis] = useState([]);
    const [opt_softwares, setSoftwares] = useState([]);
    const [opt_pcs, setPCs] = useState([]);
    const [opt_monitors, setMonitors] = useState([]);
    const [opt_tubes, setTubes] = useState([]);
    const [opt_detectors, setDetectors] = useState([]);
    const [opt_printers, setPrinters] = useState([]);
    const [opt_scanners, setScanners] = useState([]);
  
    //all require fields 
    const requiredFields = ["category", "model", "item", "quantity"];
    //validate the form
    const validateForm = () => {
        const errors = {};
        requiredFields.forEach(field => {
            if (currentPart.field) {
                currentPart.field = `this field is required`;
            }
        });
        setErrorMesage(errors);
        return Object.keys(errors).length === 0;
    };
   
    //test 
    useEffect(()=>{
        console.log("current part",currentPart);
    },[currentPart]);

    //
    useEffect(() => {

        const fetchData = async (type, setter) => {
            try {
                const response = await axios.get(
                    `${backendString}/dropdown?selectedRegion=${selectedRegion}&type=${type}`,
                    {
                        headers: {
                            Authorization: sessionStorage.getItem("token"),
                        },
                    }
                );
                const models = response.data
                    .filter((item) => {
                        if (item.ForMachine) {
                            const ForMachines = item.ForMachine.split(',');
                            return ForMachines.includes(currentPart.model); // Check if machineModel exists in ForMachines
                        }
                        return true; // Include items without ForMachine
                    })
                    .map((item) => item.ModelName); // Extract ModelName
                setter(models); // Set the filtered models
            } catch (error) {
                console.error(`Error fetching ${type}:`, error);
            }
        };

        //get category
        if (currentPart?.category !== "") {
            
            //call model
            switch (currentPart.category) {
                case "PC":
                    fetchData("pc", setPCs);
                    break;
                case "Monitor":
                    fetchData("monitor", setMonitors);
                    break;
                case "X-Ray Tube":
                    fetchData("tube", setTubes);
                    break;
                case "Tube Power Supply":
                    break;
                case "Detector":
                    fetchData("fpd", setDetectors);
                    break;
                case "Chassis":
                    fetchData("cabinetassembly", setChasis);
                    break;
                case "Software":
                    fetchData("software", setSoftwares);
                    break;
                case "Printer":
                    fetchData("barcodeprinter", setPrinters);
                    break;
                case "Scanner":
                    fetchData("barcodescanner", setScanners);
                    break;
            }
        }
    }, [currentPart.category]);

    //handle field value changes
    const handleChange = (field) => (event) => {
        setErrorMesage("");
        setCurrentPart((prevPart) => ({
            ...prevPart,
            [field]: event.target.value,
        }));
    }

    const handleEdit=()=>{
        onEdit(currentPart);
    }

     //render nessary fields for this part
     const renderPartFields = (category) => {
        let options = [];
        switch (category) {
            case "Chasis":
                options = opt_chasis;
                break;
            case "Software":
                options = opt_softwares;
                break;
            case "PC":
                options = opt_pcs;
                break;
            case "Monitor":
                options = opt_monitors;
                break;
            case "X-Ray Tube":
                options = opt_tubes;
                break;
            case "Detector":
                options = opt_detectors;
                break;
            case "Printer":
                options = opt_printers;
                break;
            case "Scanner":
                options = opt_scanners;
                break;
            default:
                options = [];
                break;
        }
        if (options.length !== 0) {
            return (
                <Autocomplete
                    freeSolo
                    options={options}
                    value={currentPart.item}
                    onChange={(event, newValue) => setCurrentPart(prev => ({ ...prev, item: newValue }))}
                    renderInput={(params) => (
                        <TextField {...params} label={GetTextByRegion("Item Name or Part Number", selectedRegion)} variant="standard" margin="normal" />
                    )}
                />
            );
        } else {
            return (
                <TextField
                    label={GetTextByRegion("Item Name or Part Number", selectedRegion)}
                    variant="standard"
                    fullWidth
                    margin="normal"
                    value={currentPart.item}
                    onChange={(event) => setCurrentPart(prev => ({ ...prev, item: event.target.value || '' }))}
                />
            );
        }
    }

    return (
<Dialog open={open} onClose={onClose}>
            <DialogTitle>{GetTextByRegion("Edit Machine Part", selectedRegion)}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} justifyContent="left" alignItems="center" mt={0}>
                    <Grid item xs={6}>
                        <TextField
                            label={GetTextByRegion("Model", selectedRegion)}
                            fullWidth
                            variant="standard"
                            margin="normal"
                            value={currentPart.model}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            freeSolo
                            options={opt_category}
                            value={currentPart.category}
                            disabled={true}
                            onBlur={handleChange('category')}
                            renderInput={(params) => (
                                <TextField {...params} label={GetTextByRegion("Category", selectedRegion)} variant="standard" margin="normal" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {renderPartFields(currentPart.category)}
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={GetTextByRegion("Quantity", selectedRegion)}
                            fullWidth
                            variant="standard"
                            margin="normal"
                            type="number"
                            value={currentPart.quantity}
                            onChange={handleChange('quantity')}
                            onKeyPress={(e) => {
                                const allowedKeys = /^[0-9]+$/;
                                if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                    e.preventDefault();
                                    setErrorMesage("Error: Invalid Input!");
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={GetTextByRegion("Notes", selectedRegion)}
                            fullWidth
                            variant="standard"
                            margin="normal"
                            value={currentPart.description}
                            onChange={handleChange('description')}
                        />
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button onClick={handleEdit} color="primary" variant="contained" >
                        {GetTextByRegion("Save", selectedRegion)}
                    </Button>
                    <Button onClick={onClose} color="primary" >
                        {GetTextByRegion("Close", selectedRegion)}
                    </Button>
                </DialogActions>

            </DialogContent>
        </Dialog>
    );

}

export default EditMachinePart;